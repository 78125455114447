<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div
        class="
          header-auth
          position-relative
          pb-16
          pt-16
          border-radius-xl
          min-vh-75
        "
      >
        <v-container style="height: 100vh">
          <v-row>
            <v-col lg="4" md="6" cols="12" class="d-flex flex-column mx-auto">
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <!-- your content here -->
                <router-view></router-view>
              </fade-transition>
            </v-col>
            <v-col md="6">
              <div
                class="
                  oblique
                  position-absolute
                  top-0
                  h-100
                  d-md-block d-none
                  me-n8
                "
              >
                <div
                  class="
                    oblique-image
                    position-absolute
                    fixed-top
                    ms-auto
                    h-100
                    w-100
                    z-index-0
                    ms-n16
                  "
                  :style="`background-image: url(${randomBackgroundImage}); background-position: 50%; background-size: cover; background-repeat: no-repeat;`"
                >
                  <span class="mask bg-gradient-default opacity-6"></span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
import randomCurvedImageMixin from "@/mixins/randomCurvedImageMixin";

export default {
  name: "page-layout",

  mixins: [randomCurvedImageMixin],

  data: () => ({
    // used in randomCurvedImageMixin
    curvedPhotos: [
      "curved1.jpg",
      "curved2.jpg",
      "curved3.jpg",
      "curved4.jpg",
      "curved5.jpg",
    ],
  }),

  components: {
    FadeTransition,
    ContentFooter,
  },
};
</script>
