import axios from "@/axios";

const parseIntOrNull = (value) => (value ? parseInt(value, 10) : null);

// =====================================================================
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  resetDocuments(state) {
    state.documents = [];
    state.selectedDocumentIds = [];
  },

  resetShareFiles(state) {
    state.shareFiles = [];
  },

  set(state, { name, value }) {
    state[name] = value;
  },

  push(state, { collection, resource }) {
    state[collection].push(resource);
  },

  update(state, { collection, resource }) {
    if (!resource) return;
    state[collection] = state[collection].map((e) => {
      return e.id === resource.id ? resource : e;
    });
  },

  bulkUpdate(state, { collection, resources }) {
    if (!resources.length === 0) return;
    state[collection] = state[collection].map((e) => {
      const resource = resources.find((r) => r.id === e.id);
      return resource || e;
    });
  },

  setSelectedDocumentIds(state, ids) {
    state.selectedDocumentIds = ids;
  },

  delete(state, { collection, resource }) {
    state[collection] = state[collection].filter((oldResource) => {
      return oldResource.id !== resource.id;
    });
  },

  setCurrentResourceID(state, { resource, id = null }) {
    const parsedID = parseIntOrNull(id);
    if (state[`${resource}ID`] !== parsedID) state[`${resource}ID`] = parsedID;
  },

  toggle(state, { name, value }) {
    state[name] = value === null ? !state[name] : value;
  },

  updatePhaseEmailsWhitelist(state, whitelist) {
    if (!state.phase) return console.warn("no phase set");
    state.phase.settings.whitelistedEmails = whitelist;
  },

  updateProjectUser(state, projectUser) {
    state.phase.projectUser = projectUser;
  },

  setRoleGroups(state, roleGroups) {
    state.phase.roleGroups = roleGroups;
  },

  // =====================================================================
  // Tag
  // =====================================================================
  batchUpdateTag(state, { collection, newTag }) {
    if (!newTag) return;
    state[collection] = state[collection].map((e) => {
      e.tags = e.tags.map((tag) => {
        return tag.id === newTag.id ? newTag : tag;
      });
      return e;
    });
  },

  batchDeleteTag(state, { collection, deletedTag }) {
    state[collection] = state[collection].map((e) => {
      e.tags = e.tags.filter((tag) => {
        return tag.id !== deletedTag.id;
      });
      return e;
    });
  },
};

const actions = {
  // =====================================================================
  // Phase
  // =====================================================================
  setPhase({ commit, getters }) {
    const url = getters.phaseID ? `/v2/phases/${getters.phaseID}` : null;
    if (!url) return console.error("no phaseID set");
    return axios.get(url).then((response) => {
      commit("set", { name: "phase", value: response.data });
      return response.data;
    });
  },

  updatePhaseSettings({ commit, getters }, phase) {
    const url = getters.phase?.links?.update;
    if (!url) return;
    return axios.put(url, { phase }).then((response) => {
      commit("set", { name: "phase", value: response.data });
      return response.data;
    });
  },
  // =====================================================================
  // Project User
  // =====================================================================
  updateProjectUser({ commit, getters }, projectUser) {
    const url = getters.phase?.links?.projectUser?.update;
    if (!url) return console.error("no phase set");

    return axios
      .put(url, { projectUser })
      .then((response) => commit("updateProjectUser", response.data));
  },
  deleteProjectUserFilterTemplate(
    { commit, getters },
    { filterTemplateType, templateName },
  ) {
    const path = getters.phase?.links?.projectUser?.destroyFilterTemplate;
    if (!path) return console.error("no link found");

    const url = `${path}?filterTemplateType=${filterTemplateType}&templateName=${templateName}`;

    return axios
      .delete(url)
      .then((response) => commit("updateProjectUser", response.data));
  },
  // =====================================================================
  // Project
  // =====================================================================
  toggleDoneProject({ commit, getters }) {
    const url = getters.project?.links?.project;
    if (!url) return Promise.reject(new Error("no project set"));

    const project = { done: !getters.project.done };
    return axios.put(url, { project }).then((response) => {
      commit("set", { name: "project", value: response.data });
      return response.data;
    });
  },

  updateProjectSettings({ commit, getters }, project) {
    const url = getters.project?.links?.project;
    if (!url) return Promise.reject(new Error("no project set"));

    const phase = getters.phase;
    return axios.put(url, { project }).then((response) => {
      const value = { ...phase, project: response.data };
      commit("set", { name: "phase", value });
      return response.data;
    });
  },

  // =====================================================================
  // Document
  // =====================================================================
  setDocuments({ commit }, url) {
    return axios.get(url).then((response) => {
      commit("set", { name: "documents", value: response.data });
    });
  },

  reloadDocumentsSet({ commit }, { path, ids }) {
    const url = `${path}?ids=${encodeURIComponent(ids)}`;
    return axios.get(url).then((response) => {
      for (const document of response.data) {
        commit("update", { collection: "documents", resource: document });
      }
    });
  },

  addDocument({ commit, getters }, form) {
    const url =
      getters.project.documentLinks[getters.documentsType].documentsUrl;
    return axios.post(url, form).then((response) => {
      commit("push", { collection: "documents", resource: response.data });
      return response.data;
    });
  },

  updateDocument({ commit }, { url, payload, deleteAfterUpdate = false }) {
    return axios.put(url, payload).then((response) => {
      if (response?.data?.error == 9000) return;
      if (deleteAfterUpdate) {
        commit("delete", {
          collection: "documents",
          resource: response.data,
        });
      }
      commit("update", {
        collection: "documents",
        resource: response.data,
      });
      return response.data;
    });
  },

  deleteDocument({ commit }, { url }) {
    return axios.delete(url).then((response) => {
      commit("delete", {
        collection: "documents",
        resource: response.data,
      });
      return response.data;
    });
  },

  bulkUpdateDocuments({ commit }, { url, payload }) {
    return axios.put(url, payload).then((response) => {
      if (response?.data?.error == 9000) return;
      commit("bulkUpdate", {
        collection: "documents",
        resources: response.data,
      });
      return response.data;
    });
  },

  // =====================================================================
  // ShareFile
  // =====================================================================
  updateShareFile({ commit }, { url, payload }) {
    return axios.put(url, payload).then((response) => {
      if (response?.data?.error == 9000) return;

      const resource = response.data;
      commit("update", {
        collection: "shareFiles",
        resource,
      });
      return resource;
    });
  },
};

// =====================================================================
const getters = {
  // scopers for search on project -----------
  masterProjectID: (state) => state.phase?.masterProjectId,

  // Phases ---------------
  phase: (state) => state.phase,
  phaseID: (state) => state.phaseID,
  phaseSettings: (state) => state.phase?.settings,
  isPhasePilot: (state) => state.phase?.phasePilot,
  phaseLinks: (state) => state.phase?.links,
  phaseApprovalProcessTemplateSteps: (state) =>
    state.phase?.settings?.approvalProcessTemplates || [],
  roleGroups: (state) => state.phase?.roleGroups || [],
  approvers: (state) => state.phase?.approvers || [],

  // Phase > ProjectUser ---------------
  projectUser: (state) => state.phase?.projectUser,

  // Phase > Project ---------------
  project: (state) => state.phase?.project,
  projectID: (state) => state.phase?.project?.id,
  projectSettings: (state) => state.phase?.project?.settings,
  projectLinks: (state) => state.phase?.project?.links,
  iCan: (state) => (auth) =>
    state.phase?.projectUser?.authorizations?.includes(auth),

  // Documents ---------------
  documents: (state) => state.documents,
  selectedDocuments: (state) =>
    state.documents.filter((d) => state.selectedDocumentIds.includes(d.id)),
  selectedDocumentIds: (state) => state.selectedDocumentIds,

  // ShareFiles ---------------
  shareFiles: (state) => state.shareFiles,
};

// =====================================================================
const getDefaultState = () => {
  return {
    phase: null,
    phaseID: null,
    documents: [],
    shareFiles: [],
    selectedDocumentIds: [],
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
