// =====================================================================
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  togglePremiumPopup(state) {
    state.displayPremiumPopup = !state.displayPremiumPopup;
  },
};

// =====================================================================
const actions = {
  togglePremiumPopup({ commit }) {
    commit("togglePremiumPopup");
  },
};

// =====================================================================
const getters = {
  displayPremiumPopup: (state) => state.displayPremiumPopup,
};

// =====================================================================
const getDefaultState = () => {
  return {
    displayPremiumPopup: false,
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
