import axios from "@/axios";

// =====================================================================
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setGeojson(state, geojson) {
    state.geojson = geojson;
  },

  upsertMarkers(state, markers) {
    const geojson = state.geojson;
    markers.forEach((marker) => {
      const index = geojson.data.features.findIndex(
        (obj) => obj.properties.markerId === marker.id,
      );

      if (!marker.geolocated) {
        // If marker's coordinates are [null, null], remove it from geojson.data.features
        if (index !== -1) {
          geojson.data.features.splice(index, 1);
        }
      } else {
        // Update or add the marker to geojson.data.features
        index !== -1
          ? (geojson.data.features[index] = marker)
          : geojson.data.features.push(marker);
      }
    });
    state.geojson = structuredClone(geojson);
  },

  deleteMarkers(state, markers) {
    const geojson = state.geojson;
    geojson.data.features = geojson.data.features.filter((feature) => {
      return !markers.some(
        (marker) => marker.id === feature.properties.markerId,
      );
    });
    state.geojson = structuredClone(geojson);
  },
};

// =====================================================================
const actions = {
  setGeojson({ commit }, url) {
    return axios.get(url).then((response) => {
      commit("setGeojson", response.data);
    });
  },
};

// =====================================================================
const getters = {
  geojson: (state) => state.geojson,
};

// =====================================================================
const getDefaultState = () => {
  return {
    geojson: null,
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
