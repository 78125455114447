import i18n from "@/i18n";

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  login({ dispatch, commit }, { user, sso }) {
    localStorage.setItem("loggedIn", false);
    commit("setIsLoading", true);

    const payload = sso ? { user, sso } : { user };
    return axios
      .post("/users/sign_in", payload)
      .then((response) => {
        if (!response.data.requireOtp) {
          localStorage.setItem("loggedIn", true);
          localStorage.setItem(
            "acceptedLegalMention",
            response.data.user.acceptedLegalMention,
          );
          dispatch("users/getMe", null, { root: true });
        }
        commit("setIsLoading", false);
        return response.data;
      })
      .catch((error) => {
        dispatch("alerts/error", { message: error }, { root: true });
        commit("setIsLoading", false);
      });
  },
  logout({ dispatch }) {
    localStorage.removeItem("loggedIn");
    return axios.delete("/users/sign_out").then(() => {
      dispatch(
        "alerts/success",
        { message: i18n.t("message.success.disconnected") },
        { root: true },
      );
    });
  },
};

const getters = {
  userIsLoggedIn: () => JSON.parse(localStorage.getItem("loggedIn")) || false,
  isLoading: (state) => state.isLoading,
};

const state = () => ({
  isLoading: false,
});

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
