var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('GlobalLoadingAnimation'),(_vm.$store.getters['users/meReady'])?_c('Sidebar'):_vm._e(),(_vm.$store.getters['users/meReady'])?_c('v-main',{staticClass:"background"},[_c('router-view'),(_vm.displayPremiumAdvertise)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},model:{value:(_vm.displayPremiumAdvertise),callback:function ($$v) {_vm.displayPremiumAdvertise=$$v},expression:"displayPremiumAdvertise"}},[_c('PremiumAdvertise')],1):_vm._e(),_c('v-dialog',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],staticStyle:{"z-index":"1000"},attrs:{"content-class":"search-dialog border-radius-xl","transition":"dialog-bottom-transition","max-width":"700","scrollable":""},model:{value:(_vm.displaySearch),callback:function ($$v) {_vm.displaySearch=$$v},expression:"displaySearch"}},[_c('Search',{attrs:{"openResultOnClick":"","models":[
          { name: 'Project' },
          { name: 'Document' },
          {
            name: 'Task',
            translation: ((_vm.$t('ui.dictionary.task')) + "/" + (_vm.$t(
              'ui.dictionary.reminder'
            ))),
          },
          { name: 'Channel' },
          { name: 'ShareFile' } ]}})],1),_c('UserConfirmationAction')],1):_vm._e(),_c('GoplanObjectLayout',{key:_vm.objectViewMode})],1)}
var staticRenderFns = []

export { render, staticRenderFns }