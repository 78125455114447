import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";
import store from "../store/store";
import "@fortawesome/fontawesome-free/css/all.css";

const light = {
  primary: "#9702f3", // old -> 225CFE", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#1E96FC",
  success: "#33CA7F",
  accent: "##EF767A",
  background: "#F8F9FA",
  darkGrey: "#667799",
  lightInfo: "#ccd2dd",
  default: "#9702f3",
};
const dark = {
  primary: "#9702f3", // old -> 225CFE", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#1E96FC",
  success: "#33CA7F",
  accent: "##EF767A",
  background: "#363636",
  darkGrey: "#667799",
  lightInfo: "#ccd2dd",
  default: "#9702f3",
};

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: { dark, light },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
});

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "sm", // This is equivalent to a value of 960
  },
});
