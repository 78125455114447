import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      eventsCount: "users/eventsCount",
      unreadRoomsCount: "chats/unreadRoomsCount",
    }),
  },

  methods: {
    updatePageTitle() {
      const events = this.eventsCount ? `(${this.eventsCount} 🔔)` : "";
      const rooms = this.unreadRoomsCount
        ? `(${this.unreadRoomsCount} 💬)`
        : "";
      document.title = `${events} ${rooms} Goplan`;
    },
  },

  watch: {
    eventsCount: {
      immediate: true,
      handler() {
        this.updatePageTitle();
      },
    },
    unreadRoomsCount: {
      immediate: true,
      handler() {
        this.updatePageTitle();
      },
    },
  },
};
