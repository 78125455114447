<template>
  <v-app
    id="materialpro"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <router-view></router-view>
    <GoplanAudio />

    <!-- retain-focus=false on document and approval dialog so that chat can be shown -->
    <!-- https://github.com/vuetifyjs/vuetify/issues/8640 -->
    <v-snackbar
      v-if="alert.visible"
      bottom
      :left="!$vuetify.breakpoint.mobile"
      :timeout="alert.timeout"
      v-model="alert"
      :color="alert.type"
      :style="{
        paddingLeft: $vuetify.breakpoint.mobile ? '0px' : '60px',
      }"
    >
      <span
        v-if="typeof alert.message === 'string'"
        class="text-sm font-weight-bold white-space-pre-line"
        v-text="$t(alert.message)"
      />
      <span
        v-else-if="!alert.message"
        class="text-sm font-weight-bold white-space-pre-line"
        v-text="$t(alert.type)"
      />
      <div v-else class="d-flex flex-column">
        <span
          v-for="(message, i) in alert.message"
          :key="i"
          class="text-sm font-weight-bold white-space-pre-line"
          v-text="$t(message)"
        />
      </div>

      <template v-slot:action="{ attrs }">
        <div class="d-flex flex-column">
          <v-btn
            icon
            small
            elevation="0"
            :ripple="false"
            class="font-weight-600 text-capitalize px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="clearAlert"
            v-bind="attrs"
          >
            <v-icon size="24" class="text-white" v-text="alert.icon" />
          </v-btn>
          <div class="text-center" v-if="alert.tracing">
            <v-dialog v-model="dialog" width="600px">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  elevation="0"
                  max-width="136"
                  :ripple="false"
                  class="font-weight-600 text-capitalize px-6 rounded-sm"
                  color="rgba(255,255,255, .85)"
                  v-on="on"
                >
                  <v-icon size="18">fas fa-info-circle</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">More info for devs</span>
                </v-card-title>
                <v-card-text>
                  {{ alert.tracing }}
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "app",
  components: {
    GoplanAudio: () => import("@/views/goplan-components/GoplanAudio"),
  },

  // this allow to tell our app when query parameters change in the app
  created() {
    this.$watch(
      () => this.$route.params,
      (to, from) => {
        if (to == from) return;
        this.$store.commit("parameters/updateParams", to);
      },
    );
    this.$watch(
      () => this.$route.query,
      (to) => {
        this.$store.commit("parameters/updateQuery", to);
      },
    );
  },

  methods: {
    clearAlert: function () {
      this.$store.dispatch("alerts/clear");
    },
  },
  channels: {
    WebRealtimeChannel: {
      connected() {},
      received(data) {
        if (data.hasOwnProperty("room")) {
          let room = data.room;
          if (this.roomID === room.id) {
            room = { ...room, unreadCount: 0 };
          }

          this.$store.dispatch(data.action, room);
          return;
        }

        if (data.hasOwnProperty("message")) {
          this.$store.dispatch(data.action, data.message);
          return;
        }
        if (data.hasOwnProperty("annotations")) {
          return;
        }

        if (data.params) {
          if ("eventsCount" in data.params) {
            this.$store.dispatch("users/newEvent", true);
          }

          this.$store.dispatch(data.action, data.params);
        }
      },
      disconnected() {},
    },
  },
  computed: {
    ...mapGetters({
      myUserID: "users/myUserID",
      roomID: "chats/roomID",
    }),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    alert: {
      get() {
        return this.$store.state.alerts;
      },
      set(value) {
        if (value) return;
        this.clearAlert();
      },
    },
    colors() {
      return this.$store.getters.colors;
    },
    roomID() {
      const query = this.$store.getters["parameters/currentQuery"](["roomID"]);
      const id = query.roomID;
      return this.$options.filters.parseIntOrNull(id);
    },
  },
  data: () => ({
    dialog: false,
    multiline: true,
    vertical: true,
  }),

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (
        from.path == "/authentication/login" &&
        to.path != "/authentication/login"
      ) {
        this.$store.dispatch("users/getMe").then(() => {
          this.$store.dispatch("masterProjects/getAll");
          this.$store.dispatch("chats/setRooms");
        });
      }
      // clear alert on location change
      this.$store.dispatch("alerts/clear");
    },

    myUserID(userID) {
      if (userID) {
        this.$cable.unsubscribe({
          channel: "WebRealtimeChannel",
          type: "notifications",
          id: userID,
        });
        this.$cable.subscribe({
          channel: "WebRealtimeChannel",
          type: "notifications",
          id: userID,
        });

        this.$cable.unsubscribe({
          channel: "WebRealtimeChannel",
          type: "rooms",
          id: userID,
        });
        this.$cable.subscribe({
          channel: "WebRealtimeChannel",
          type: "rooms",
          id: userID,
        });
      }
    },

    roomID(newID, oldID) {
      if (oldID && oldID != newID) {
        this.$cable.unsubscribe({
          channel: "WebRealtimeChannel",
          type: "messages",
          id: oldID,
        });
      }
      if (newID) {
        this.$cable.subscribe({
          channel: "WebRealtimeChannel",
          type: "messages",
          id: newID,
        });
      }
    },
  },
  mounted() {
    this.$store.commit("parameters/updateParams", this.$route.params);
    this.$store.commit("parameters/updateQuery", this.$route.query);
    if (this.$store.getters["authentications/userIsLoggedIn"]) {
      this.$store.dispatch("users/getMe").then(() => {
        this.$store.dispatch("masterProjects/getAll");
        this.$store.dispatch("chats/setRooms");
      });
    }
  },
};
</script>

<style lang="scss">
.goplan-grey--text {
  color: rgba(0, 0, 0, 0.54) !important;
}

.v-item-group {
  border-radius: 1rem !important;
}

.v-tabs-slider-wrapper,
.v-tab--active {
  font-weight: bolder !important;
}

.horizontal-form {
  table {
    width: 100%;
    border-spacing: 0;
  }
  table tr {
    td {
      label {
        display: block;
        margin: 0 !important;
        display: flex;
        align-items: center;
      }
      .v-input {
        border-radius: 0;
        padding-left: 0;
        border: 1px solid $background-color;
      }
    }
  }
  table tr:first-child {
    td {
      border-top-left-radius: 16px;
      label {
        margin: 0 !important;
      }
      .v-input {
        border-top-right-radius: 16px;
      }
    }
  }
  table tr:last-child {
    td {
      border-bottom-left-radius: 16px;
      label {
        margin: 0 !important;
      }
      .v-input {
        border-bottom-right-radius: 16px;
      }
    }
  }
}

.vue-simple-context-menu {
  position: fixed !important;
  z-index: 1000;
}

.v-dialog.dialog-position-top {
  position: absolute;
  top: 0;
}

.v-text-field.big-input input {
  font-size: 1.2em !important;
  line-height: normal !important;
}

.v-text-field.bigger-input input {
  font-size: 1.5em !important;
}
.flat-text-field .v-input__slot {
  padding: 0px !important;
}
.v-textarea.v-textarea-dense textarea {
  line-height: 18px;
  margin: 0px !important;
}
</style>
