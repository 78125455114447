import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

/**
 * @returns {(string|null)} returns only a supported language we support or null
 */
function getNavigatorLanguage() {
  let supportedLanguages = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
  let navigatorLanguage = navigator.language.split("-")[0];
  if (supportedLanguages.includes(navigatorLanguage)) {
    return navigatorLanguage;
  }
  return null;
}

export default new VueI18n({
  locale: getNavigatorLanguage() || "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages(),
});
