<template>
  <GoplanDialog :value="!!action" @change="action = null" scrollable>
    <template #title>
      <span v-text="action.message" />
    </template>
    <template #body>
      <v-card class="pa-4 border-radius-xl">
        <span
          v-if="action.description"
          class="white-space-pre-line text-subtitle-2"
        >
          {{ action.description }}
        </span>
        <div v-if="action.requirements" class="py-6">
          <div v-if="action.requirements.form">
            <!-- --------------------------------------------------------------------- -->
            <!-- BEGIN assignee                                                        -->
            <!-- --------------------------------------------------------------------- -->
            <v-row
              v-if="
                Object.keys(action.requirements.form).includes(
                  'replacementUserId',
                )
              "
              align="center"
            >
              <v-col class="py-0" cols="2" style="min-width: 20ch">
                <label :class="$classLabelDisabled">{{
                  $t("ui.dictionary.replacement_user")
                }}</label>
              </v-col>
              <v-col class="py-0" cols="auto">
                <UserSelector
                  v-model="form.replacementUserId"
                  :fetchUrl="action.requirements.links.users"
                  returnValue="id"
                  withOffset
                />
              </v-col>
            </v-row>
            <!-- --------------------------------------------------------------------- -->
            <!-- END assignee                                                          -->
            <!-- --------------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------------- -->
            <!-- BEGIN template name                                                        -->
            <!-- --------------------------------------------------------------------- -->
            <v-row
              v-if="
                Object.keys(action.requirements.form).includes(
                  'replacementTemplateName',
                )
              "
              align="center"
            >
              <v-col class="py-0" cols="4" style="min-width: 20ch">
                <label :class="$classLabelDisabled">{{
                  $t("ui.dictionary.replacement_template_name")
                }}</label>
              </v-col>
              <v-col class="py-0" cols="auto">
                <v-select
                  v-model="form.replacementTemplateName"
                  :items="action.requirements.templateNames"
                  hide-details
                  dense
                  :class="$classInputOutlined"
                  :placeholder="$t('ui.dictionary.none')"
                  returnObject
                  solo
                  clearable
                  :prepend-inner-icon="$icons.template"
                  append-icon=""
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-title
                      class="
                        text-overline
                        font-weight-bolder
                        d-flex
                        justify-space-between
                        align-center
                      "
                    >
                      <span v-text="item" />
                    </v-list-item-title>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <!-- --------------------------------------------------------------------- -->
            <!-- END template name                                                          -->
            <!-- --------------------------------------------------------------------- -->
          </div>
        </div>

        <v-list
          v-if="action.impactedResources && !isEmpty(action.impactedResources)"
          dense
        >
          <span
            class="font-weight-bold text-uppercase text-xs text-dark"
            v-text="$t('ui.dictionary.impacted_elements')"
          />
          <v-list-group
            v-for="(dependency, i) in Object.entries(
              action.impactedResources,
            ).filter(
              (dependency) =>
                dependency[1].length != 0 && dependency[0] != 'price',
            )"
            :key="'type-' + i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="dependency[0]" />
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(item, j) in dependency[1]"
              :key="`dependency-${i}-${j}`"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.title || item.prettyName || item"
                />
                <v-divider class="mt-2" />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <div class="d-flex justify-end">
          <v-btn
            elevation="0"
            :class="$classBtnOutlinedPrimary"
            :loading="loading"
            text
            @click="closeDialog"
            >{{ $t("ui.button.cancel") }}</v-btn
          >
          <v-btn
            :class="$classBtnPrimary"
            :loading="loading"
            dark
            @click="submitEvent"
            :elevation="0"
            >{{ $t("ui.button.continue") }}</v-btn
          >
        </div>
      </v-card>
    </template>
  </GoplanDialog>
</template>

<script>
import axios from "@/axios";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  data: () => ({
    loading: false,
    active: false,
    form: {},
  }),

  components: {
    UserSelector: () => import("@/views/goplan-components/UserSelector.vue"),
    GoplanDialog: () => import("@/views/goplan-components/GoplanDialog.vue"),
  },

  computed: {
    ...mapGetters({
      myCompany: "users/myCompany",
    }),
    action: {
      get() {
        return this.$store.getters["userConfirmationAction"];
      },
      set(value) {
        if (!value) this.closeDialog();
      },
    },
    contractEndAt() {
      return this.myCompany.billingSetting.contractEndAt;
    },
  },

  methods: {
    isEmpty: function (object) {
      return isEmpty(object);
    },
    submitEvent: function () {
      this.loading = true;
      const verb = this.action.verb.toLowerCase();

      let payload = this.action.requirements?.form
        ? { ...this.action.payload, ...this.form }
        : this.action.payload;

      payload = verb === "delete" ? { data: payload } : payload;

      axios[verb](this.action.url, payload)
        .then((response) => {
          this.$store.dispatch("alerts/success");
          this.handleResponseObject(response.data);
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },

    handleResponseObject: function (object) {
      switch (object.type) {
        case "Document":
          this.$store.commit("current/update", {
            collection: "documents",
            resource: object,
          });
          break;
        case "Phase":
          this.$store.commit("current/set", {
            name: "phase",
            value: object,
          });
          break;
        default:
          break;
      }
    },

    closeDialog: function () {
      this.$store.commit("userConfirmationAction", false);
      this.active = false;
    },
  },
};
</script>
