import axios from "@/axios";

const mutations = {
  saveCompany(state, { companyName, companyInfo }) {
    state.company = companyInfo;
    state.company.name = companyName;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  createCompany({ commit }, company) {
    commit("setIsLoading", true);
    return axios
      .post("/company_registration/check_company", { company })
      .then((response) => {
        if (response.data.success == false) return false;

        commit("saveCompany", {
          companyName: response.data.companyName,
          companyInfo: company,
        });
        return true;
      })
      .finally(() => commit("setIsLoading", false));
  },
};

const getters = {
  companyName: (state) => state.company.name,
  user: (state) => state.user,
  isLoading: (state) => state.isLoading,
};

const state = () => ({
  company: {
    id: null,
    name: null,
  },
  user: {
    email: null,
    firstName: null,
    lastName: null,
  },
  isLoading: false,
});

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
