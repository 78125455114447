<template>
  <v-app>
    <GlobalLoadingAnimation />

    <Sidebar v-if="$store.getters['users/meReady']" />

    <v-main v-if="$store.getters['users/meReady']" class="background">
      <router-view />

      <v-dialog
        transition="dialog-bottom-transition"
        v-if="displayPremiumAdvertise"
        v-model="displayPremiumAdvertise"
        max-width="700"
      >
        <PremiumAdvertise />
      </v-dialog>

      <v-dialog
        content-class="search-dialog border-radius-xl"
        v-hotkey="keymap"
        transition="dialog-bottom-transition"
        v-model="displaySearch"
        max-width="700"
        scrollable
        style="z-index: 1000"
      >
        <Search
          openResultOnClick
          :models="[
            { name: 'Project' },
            { name: 'Document' },
            {
              name: 'Task',
              translation: `${$t('ui.dictionary.task')}/${$t(
                'ui.dictionary.reminder',
              )}`,
            },
            { name: 'Channel' },
            { name: 'ShareFile' },
          ]"
        />
      </v-dialog>

      <UserConfirmationAction />
    </v-main>

    <GoplanObjectLayout :key="objectViewMode" />
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import { mapGetters } from "vuex";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import Sidebar from "@/components/Sidebar.vue";
import dynamicWindowPageTitleMixin from "@/mixins/dynamicWindowPageTitleMixin";
import GlobalLoadingAnimation from "@/views/goplan-components/Animations/GlobalLoadingAnimation.vue";
import GoplanObjectLayout from "@/views/goplan-components/GoplanObjectLayout";
import PerfectScrollbar from "perfect-scrollbar";
import PremiumAdvertise from "@/views/goplan-components/PremiumAdvertise.vue";
import Search from "@/views/goplan-components/Search.vue";
import UserConfirmationAction from "@/views/goplan-components/UserConfirmationAction.vue";

import "perfect-scrollbar/css/perfect-scrollbar.css";

export default {
  mixins: [dynamicWindowPageTitleMixin],

  components: {
    Sidebar,
    GlobalLoadingAnimation,
    GoplanObjectLayout,
    PremiumAdvertise,
    Search,
    UserConfirmationAction,
  },

  computed: {
    ...mapGetters({
      darkMode: "users/darkMode",
      isPremium: "users/isPremium",
      objectViewMode: "users/objectViewMode",
    }),
    displayPremiumAdvertise: {
      get() {
        return this.$store.getters["premium/displayPremiumPopup"];
      },
      set() {
        this.$store.commit("premium/togglePremiumPopup");
      },
    },
    displaySearch: {
      get() {
        return this.$store.getters["displaySearch"];
      },
      set() {
        this.$store.commit("toggleSearch");
      },
    },
    keymap() {
      return {
        "ctrl+k": this.show,
        "command+k": this.show,
      };
    },
    isInTrial() {
      if (!this.$store.getters["users/meReady"]) return;

      const isPremium = this.$store.getters["users/isPremium"];
      const remainingTrialDays =
        this.$store.getters["users/remainingTrialDays"];
      return (
        isPremium && remainingTrialDays !== null && remainingTrialDays >= 0
      );
    },
  },

  data: () => ({}),

  methods: {
    show() {
      this.$store.commit("toggleSearch");
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },

  watch: {
    darkMode: {
      immediate: true,
      handler(darkMode) {
        this.$vuetify.theme.dark = darkMode;
      },
    },
  },
};
</script>

<style lang="scss">
.search-dialog {
  position: absolute;
  top: 0;
}
</style>
