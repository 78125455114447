export default {
  computed: {
    randomBackgroundImage() {
      const backgroundImages = this.curvedPhotos || [
        "curved0.jpg",
        "curved1.jpg",
        "curved2.jpg",
        "curved3.jpg",
        "curved4.jpg",
        "curved5.jpg",
      ];

      // Generate a random index to select a random image
      const randomIndex = Math.floor(Math.random() * backgroundImages.length);

      // Construct the URL for the random background image
      return require(`@/assets/img/curved-images/${backgroundImages[randomIndex]}`);
    },
  },
};
