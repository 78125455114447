var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GoplanDialog',{attrs:{"value":!!_vm.action,"scrollable":""},on:{"change":function($event){_vm.action = null}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.action.message)}})]},proxy:true},{key:"body",fn:function(){return [_c('v-card',{staticClass:"pa-4 border-radius-xl"},[(_vm.action.description)?_c('span',{staticClass:"white-space-pre-line text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.action.description)+" ")]):_vm._e(),(_vm.action.requirements)?_c('div',{staticClass:"py-6"},[(_vm.action.requirements.form)?_c('div',[(
              Object.keys(_vm.action.requirements.form).includes(
                'replacementUserId'
              )
            )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",staticStyle:{"min-width":"20ch"},attrs:{"cols":"2"}},[_c('label',{class:_vm.$classLabelDisabled},[_vm._v(_vm._s(_vm.$t("ui.dictionary.replacement_user")))])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('UserSelector',{attrs:{"fetchUrl":_vm.action.requirements.links.users,"returnValue":"id","withOffset":""},model:{value:(_vm.form.replacementUserId),callback:function ($$v) {_vm.$set(_vm.form, "replacementUserId", $$v)},expression:"form.replacementUserId"}})],1)],1):_vm._e(),(
              Object.keys(_vm.action.requirements.form).includes(
                'replacementTemplateName'
              )
            )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",staticStyle:{"min-width":"20ch"},attrs:{"cols":"4"}},[_c('label',{class:_vm.$classLabelDisabled},[_vm._v(_vm._s(_vm.$t("ui.dictionary.replacement_template_name")))])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('v-select',{class:_vm.$classInputOutlined,attrs:{"items":_vm.action.requirements.templateNames,"hide-details":"","dense":"","placeholder":_vm.$t('ui.dictionary.none'),"returnObject":"","solo":"","clearable":"","prepend-inner-icon":_vm.$icons.template,"append-icon":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-title',{staticClass:"\n                      text-overline\n                      font-weight-bolder\n                      d-flex\n                      justify-space-between\n                      align-center\n                    "},[_c('span',{domProps:{"textContent":_vm._s(item)}})])]}}],null,false,3595319679),model:{value:(_vm.form.replacementTemplateName),callback:function ($$v) {_vm.$set(_vm.form, "replacementTemplateName", $$v)},expression:"form.replacementTemplateName"}})],1)],1):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.action.impactedResources && !_vm.isEmpty(_vm.action.impactedResources))?_c('v-list',{attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-bold text-uppercase text-xs text-dark",domProps:{"textContent":_vm._s(_vm.$t('ui.dictionary.impacted_elements'))}}),_vm._l((Object.entries(
            _vm.action.impactedResources
          ).filter(
            function (dependency) { return dependency[1].length != 0 && dependency[0] != 'price'; }
          )),function(dependency,i){return _c('v-list-group',{key:'type-' + i,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(dependency[0])}})],1)]},proxy:true}],null,true)},_vm._l((dependency[1]),function(item,j){return _c('v-list-item',{key:("dependency-" + i + "-" + j)},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title || item.prettyName || item)}}),_c('v-divider',{staticClass:"mt-2"})],1)],1)}),1)})],2):_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{class:_vm.$classBtnOutlinedPrimary,attrs:{"elevation":"0","loading":_vm.loading,"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("ui.button.cancel")))]),_c('v-btn',{class:_vm.$classBtnPrimary,attrs:{"loading":_vm.loading,"dark":"","elevation":0},on:{"click":_vm.submitEvent}},[_vm._v(_vm._s(_vm.$t("ui.button.continue")))])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }