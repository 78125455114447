import { render, staticRenderFns } from "./GlobalLoadingAnimation.vue?vue&type=template&id=774b9f39&scoped=true"
import script from "./GlobalLoadingAnimation.vue?vue&type=script&lang=js"
export * from "./GlobalLoadingAnimation.vue?vue&type=script&lang=js"
import style0 from "./GlobalLoadingAnimation.vue?vue&type=style&index=0&id=774b9f39&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774b9f39",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
