import axios from "@/axios";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import { orderBy } from "lodash";

const parseIntOrNull = (value) => (value ? parseInt(value, 10) : null);

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  // room
  setRooms(state, rooms = []) {
    state.rooms = rooms.map((room) => {
      const users = room.users.map((user) => {
        user._id = user.id.toString();
        return user;
      });
      const possibleUsers = room.possibleUsers.map((user) => {
        user._id = user.id;
        return user;
      });
      return { ...room, users, possibleUsers };
    });
  },
  upsert(state, room) {
    state.rooms.some((currentRoom) => currentRoom.id === room.id)
      ? this.commit("chats/update", room)
      : this.commit("chats/addRoom", room);
  },
  addRoom(state, room) {
    state.rooms = [room, ...state.rooms];
    if (
      room.channelUser?.id === this.getters["users/myUserID"] &&
      room.ChannelUser?.unread
    ) {
      this.commit("sounds/play", "goplan-new-room");
    }
  },
  selectRoomID(state, roomID) {
    state.roomID = roomID;
  },
  update(state, room) {
    const index = state.rooms.findIndex(
      (currentRoom) => currentRoom.id === room.id,
    );
    // if new message make sound
    if (
      state.roomID != room.id &&
      room.lastMessage &&
      state.rooms[index].lastMessage?.id !== room.lastMessage?.id
    ) {
      this.commit("sounds/play", "goplan-new-message");
    }
    const users = room.users.map((user) => {
      user._id = user.id;
      return user;
    });
    const possibleUsers = room.possibleUsers.map((user) => {
      user._id = user.id;
      return user;
    });
    if (index !== -1) {
      state.rooms.splice(index, 1, { ...room, users, possibleUsers });
    }
  },
  addUserToRoom(state, user) {
    const roomIdx = state.rooms.findIndex((room) => room.id == state.roomID);
    const currentUsers = state.rooms[roomIdx].users;
    if (!currentUsers.some((currentUser) => currentUser.id === user.id)) {
      state.rooms[roomIdx].users.push(user);
    }
  },
  leaveRoom(state, leftRoom) {
    const index = state.rooms.findIndex(
      (currentRoom) => currentRoom.id === leftRoom.id,
    );
    if (index !== -1) {
      state.rooms.splice(index, 1);
    }
  },
  // message
  setMessages(state, messages = []) {
    state.messages = messages;
  },
  addMessage(state, newMessage) {
    // add to messages
    const existedMessage = state.messages.some(
      (message) => message.id === newMessage.id,
    );
    if (!existedMessage) {
      state.messages.push({ ...newMessage, _id: newMessage.id });
    }
    // if new message make sound
    if (newMessage.senderId != this.getters["users/myUserID"]) {
      this.commit("sounds/play", "goplan-new-message");
    }

    // add to room
    state.rooms = state.rooms.map((room) => {
      if (room.id !== newMessage.channelId) return room;
      return room.id === state.roomID
        ? {
            ...room,
            unread: false,
            unreadCount: 0,
            lastMessage: { ...newMessage, _id: newMessage.id },
          }
        : {
            ...room,
            unread: true,
            unreadCount: room.unreadCount + 1,
            lastMessage: { ...newMessage, _id: newMessage.id },
          };
    });
  },

  addProcessingMessage(state, newMessage) {
    state.processingMessages.push(newMessage);
  },
  addProcessedMessageAttachment(state, { uuid, attachment }) {
    const processedMessagesAttachments = {
      ...state.processedMessagesAttachments,
    };
    if (state.processedMessagesAttachments[uuid]) {
      processedMessagesAttachments[uuid].push(attachment);
    } else {
      processedMessagesAttachments[uuid] = [attachment];
    }

    state.processedMessagesAttachments = processedMessagesAttachments;
  },

  updateProgress(state, { processingMessageUUID, filename, progress }) {
    state.processingMessages = state.processingMessages.map((message) => {
      if (message.processingUUID != processingMessageUUID) return message;

      message.files = message.files.map((file) => {
        if (file.data.name != filename) return file;

        file.progress = progress;
        return file;
      });

      return message;
    });
  },

  removeProcessingMessage(state, uuid) {
    state.processingMessages = state.processingMessages.filter(
      (old) => old.processingUUID.toString() !== uuid.toString(),
    );
  },

  removeProcessedMessageAttachments(state, uuid) {
    delete state.processedMessagesAttachments[uuid];
  },

  updateMessage(state, newMessage) {
    const index = state.messages
      .map((message) => message.id)
      .indexOf(newMessage.id);
    state.messages.splice(index, 1, { ...newMessage, _id: newMessage.id });
  },
};

const actions = {
  // used to create room from the CreateRoom modal
  createRoom({ commit, _getters, rootGetters }, { url, channel }) {
    const myUserID = rootGetters["users/myUserID"];

    if (!url) return console.error("Create room url on channelable not found");

    return axios.post(url, { channel }).then((response) => {
      const room = response.data;
      const users = room.users.map((user) => {
        user["_id"] = user.id;
        return user;
      });
      room.users = users;

      commit("addRoom", { room, myUserID });
      return room;
    });
  },
  // used for create or update room from the action cable broadcast
  reloadRoom({ commit, _getters, rootGetters }, newRoom) {
    const myUserID = rootGetters["users/myUserID"];

    // when PhaseChannel add only if the user is in the users of the room
    // otherwise add if the user is in the possibleUsers
    const source = newRoom.channelable ? "possibleUsers" : "users";
    const roomShouldBeAdded = newRoom[source]
      .map((user) => user.id)
      .includes(myUserID);
    if (!roomShouldBeAdded) return;

    // make sure we have the url to fetch the room
    const url = newRoom?.links?.show;
    if (!url) return console.error("no url found on broadcasted room");

    return axios.get(url).then((response) => {
      commit("upsert", response.data);
    });
  },
  setRooms({ commit }) {
    const url = "/v2/channels";
    return axios.get(url).then((response) => {
      const rooms = response.data.map((room) => {
        const users = room.users.map((user) => {
          user["_id"] = user.id.toString();
          return user;
        });
        room.users = users;

        return room;
      });

      rooms.forEach((room) => commit("upsert", room));
    });
  },
  setRoomsFromIds({ commit }, ids) {
    const url = "/v2/channels";
    const params = { ids };
    return axios.get(url, { params }).then((response) => {
      const rooms = response.data.map((room) => {
        const users = room.users.map((user) => {
          user["_id"] = user.id.toString();
          return user;
        });
        room.users = users;

        return room;
      });

      rooms.forEach((room) => commit("upsert", room));
    });
  },
  selectRoomID({ commit }, roomID) {
    const parsedID = parseIntOrNull(roomID);
    commit("selectRoomID", parsedID);
  },
  inviteUserInCurrentRoom({ commit, getters }, user) {
    const channelId = getters.room.id;
    const userId = user.id;
    const url = "/v2/channel_users";
    axios.post(url, { channelUser: { userId, channelId } }).then(() => {
      commit("addUserToRoom", user);
    });
  },
  // TODO: refactor the 2 following methods
  update({ commit, getters }, room) {
    const url = getters.room.links.update;
    return axios
      .put(url, { channel: room })
      .then((response) => commit("update", response.data));
  },
  updateRoom({ commit, dispatch }, { item, channel }) {
    const url = item.links.update;
    if (!url) return;
    return axios.put(url, { channel }).then((response) => {
      const newRoom = response.data;
      commit("update", newRoom);
      dispatch("alerts/success", {}, { root: true });
      return newRoom;
    });
  },
  readRoom({ commit }, room) {
    commit("update", { ...room, unread: false, unreadCount: 0 });
  },
  deleteRoom({ commit }, room) {
    const url = room.links.destroy;
    if (!url) return;

    axios.delete(url).then((response) => {
      commit("leaveRoom", response.data);
    });
  },
  leaveRoom({ commit }, room) {
    const url = room.links.leaveChannel;
    if (!url) {
      axios.get(room.links.show).then((response) => {
        return axios
          .delete(response.data.links.leaveChannel)
          .then((response) => {
            commit("leaveRoom", response.data);
          });
      });
    } else {
      return axios.delete(url).then((response) => {
        commit("leaveRoom", response.data);
      });
    }
  },
  // message
  loadMessages({ commit }, room) {
    return axios.get(room.links.listMessages).then((response) => {
      const messages = response.data.map((message) => {
        message["_id"] = message.id;
        message["senderId"] = message.senderId;
        return message;
      });
      commit("setMessages", messages);
    });
  },
  sendMessage({ commit, getters }, message) {
    const url = getters.room.links.sendMessage;
    return axios.post(url, message).then((response) => {
      commit("addMessage", response.data);
    });
  },

  sendProcessedMessage({ commit, getters }, uuid) {
    const message = {
      ...getters.processingMessageWithUUID(uuid),
      messageAttachmentsAttributes: getters.processedMessagesAttachments[uuid],
    };

    return axios.post(message.link, { message }).then((response) => {
      commit("addMessage", response.data);
      commit("removeProcessingMessage", uuid);
      commit("removeProcessedMessageAttachments", uuid);
    });
  },
  addProcessingMessage({ commit }, message) {
    const processingUUID = new Date().getTime();
    commit("addProcessingMessage", { ...message, processingUUID });
    return processingUUID;
  },
  processMessage({ commit, getters }, uuid) {
    const companionUrl = `${process.env.VUE_APP_FULL_BASE_URL}/documents`;
    const message = getters.processingMessageWithUUID(uuid);
    const uppy = new Uppy({
      debug: false,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000 * 1024 * 1024,
        minNumberOfFiles: 1,
        maxNumberOfFiles: 5,
      },
    })
      .use(AwsS3Multipart, {
        companionUrl: companionUrl,
      })
      .on("upload-progress", (file, progress) => {
        progress = Math.round(
          (progress.bytesUploaded / progress.bytesTotal) * 100,
        );
        commit("updateProgress", {
          processingMessageUUID: uuid,
          filename: file.data.name,
          progress: progress,
        });
      })
      .on("upload-success", (attachment, response) => {
        const idMatches = response.uploadURL.match(/\/cache\/([^?]+)/);
        const data = JSON.stringify({
          id: idMatches[1], // extract key without prefix
          storage: "documents_cache",
          metadata: {
            size: attachment.size,
            filename: attachment.name,
            mimeType: attachment.type,
          },
        });

        const form = {
          file: data,
        };
        commit("addProcessedMessageAttachment", {
          uuid: uuid,
          attachment: form,
        });
      });
    return uppy.addFiles(message.files);
  },

  deleteMessage({ commit }, message) {
    axios
      .delete(message.links.destroy)
      .then((response) => commit("updateMessage", response.data));
  },

  addNewMessage({ commit }, message) {
    commit("addMessage", message);
  },
};

const getters = {
  channelable: (state) => state.channelable,
  rooms: (state) =>
    orderBy(
      state.rooms,
      ["unread", "channelUser.priorityLevel", "lastMessage.sortableDate"],
      ["desc", "desc", "asc"],
    ),
  roomsWithCurrentUser: (state, getters, rootState, rootGetters) => {
    const currentUser = rootGetters["users/myUserID"];
    if (!getters.rooms || !currentUser) return [];
    return getters.rooms.filter((room) => room.channelUser);
  },
  unreadRoomsCount: (state) => state.rooms.filter((room) => room.unread).length,
  hasUnreadRooms: (state) => state.rooms.some((room) => room.unread),
  roomsByMasterProjects: (_state, getters) => {
    return getters.rooms.reduce((result, item) => {
      const masterProjectId = item.masterProject?.id || 0;
      if (!result[masterProjectId]) {
        result[masterProjectId] = [];
      }
      result[masterProjectId].push(item);
      return result;
    }, {});
  },
  roomsByPhases: (_state, getters) => {
    return getters.rooms.reduce((result, item) => {
      const phaseId = item.phase?.id || 0;
      if (!result[phaseId]) {
        result[phaseId] = [];
      }
      result[phaseId].push(item);
      return result;
    }, {});
  },
  room: (state) => state.rooms.find((room) => room.id == state.roomID),
  roomID: (state) => state.roomID,
  messages: (state) => [...state.messages, ...state.processingMessages],
  processingMessages: (state) => state.processingMessages,
  processingMessageWithUUID: (state) => (uuid) =>
    state.processingMessages.find((message) => message.processingUUID == uuid),
  processedMessagesAttachments: (state) => state.processedMessagesAttachments,
};

// =====================================================================
const getDefaultState = () => {
  return {
    rooms: [],
    roomID: null,
    messages: [],
    processingMessages: [],
    processedMessagesAttachments: {},
    channelable: null, // used to activate the CreateRoom modal
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
