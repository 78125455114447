<template>
  <div v-if="$vuetify.breakpoint.mobile || objectViewMode === 'modal'">
    <v-dialog
      :value="viewerObject.objectType && viewerObject.objectType !== 'Room'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="dialog-bottom-transition"
      persistent
      :retain-focus="false"
    >
      <v-card
        v-if="viewerObject.objectType"
        class="border-radius-xl"
        height="90vh"
        style="overflow: hidden"
        flat
      >
        <ShowDocument
          v-if="'Document' === viewerObject.objectType"
          :documentID="viewerObject.objectID | parseIntOrNull"
          :key="viewerObject.objectID"
          @close="closeObjectDialog"
        />
        <ShowTask
          v-if="viewerObject.objectType == 'Task'"
          :taskID="viewerObject.objectID | parseIntOrNull"
          :key="viewerObject.objectID"
          @close="closeObjectDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      :value="viewerObject.objectType && viewerObject.objectType === 'Room'"
      @click:outside="closeObjectDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="dialog-bottom-transition"
      max-width="700px"
      :retain-focus="false"
    >
    </v-dialog>
  </div>
  <v-navigation-drawer
    v-else
    :value="
      viewerObject.constructor === Object &&
      Object.keys(viewerObject).length > 0 &&
      (viewerObject.objectID > 0 || viewerObject.objectType === 'Room')
    "
    right
    ref="drawer"
    app
    hide-overlay
    :width="navigation.width ? navigation.width.replace('px', '') : 500"
  >
    <v-card
      v-if="viewerObject.objectType"
      tile
      height="100dvh"
      width="100%"
      style="overflow: hidden"
      flat
    >
      <ShowDocument
        v-if="'Document' === viewerObject.objectType"
        :documentID="viewerObject.objectID | parseIntOrNull"
        :key="viewerObject.objectID"
        @close="closeObjectDialog"
      />
      <ShowTask
        v-if="viewerObject.objectType == 'Task'"
        :taskID="viewerObject.objectID | parseIntOrNull"
        :key="viewerObject.objectID"
        @close="closeObjectDialog"
      />
      <Chat v-if="viewerObject.objectType === 'Room'" />
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GoplanObjectLayout",

  components: {
    Chat: () => import("@/views/goplan-components/Chats/Chat"),
    ShowDocument: () => import("@/views/documents/Document"),
    ShowTask: () => import("@/views/tasks/Task"),
  },

  mounted() {
    if (this.objectViewMode && this.$refs.drawer) {
      this.navigation.width = this.objectSidebarWidth || DEFAULT_SIDEBAR_WIDTH;
      this.setBorderWidth();
      this.setEvents();
    }
  },

  computed: {
    ...mapGetters({
      objectViewMode: "users/objectViewMode",
      objectSidebarWidth: "users/objectSidebarWidth",
    }),
    direction() {
      return this.navigation.shown === false ? "Open" : "Closed";
    },
    viewerObject() {
      return this.$store.getters["parameters/currentQuery"]([
        "objectType",
        "objectID",
      ]);
    },
    navigationWidth() {
      return this.navigation.width;
    },
  },

  watch: {
    objectSidebarWidth: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.navigation.width = newVal;
        }
      },
    },
    navigationWidth: function (newVal, oldVal) {
      if (oldVal && newVal && newVal !== oldVal) {
        this.updateSidebarWidthUserSettings(newVal);
        this.setBorderWidth();
        this.setEvents();
      }
    },
  },

  data: () => ({
    navigation: {
      shown: false,
      width: null,
      borderSize: 3,
    },
  }),

  methods: {
    closeObjectDialog: function () {
      this.$router.push({
        path: this.$router.currentRoute.path,
        query: this.$options.filters.filterOut(
          this.$route.query,
          this.$objectShowViewKeys,
        ),
      });
    },
    setBorderWidth() {
      const drawer = this.$refs.drawer;
      if (!drawer) return console.warn("drawer not found");

      let i = drawer.$el.querySelector(".v-navigation-drawer__border");
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      const drawer = this.$refs.drawer;
      if (!drawer) return console.warn("drawer not found");
      const minSize = this.navigation.borderSize;
      const el = drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
            e.preventDefault();
          }
        },
        false,
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false,
      );
    },
    updateSidebarWidthUserSettings: function (objectSidebarWidth) {
      if (objectSidebarWidth === this.objectSidebarWidth) return;
      this.$store.dispatch("users/updateMeAppSettings", {
        key: "objectSidebarWidth",
        value: objectSidebarWidth
          ? objectSidebarWidth
          : this.objectSidebarWidth,
      });
    },
  },
};
</script>
