<template>
  <v-dialog
    :value="showDialog"
    fullscreen
    persistent
    no-click-animation
    transition="fade-transition"
    content-class="overflow-hidden h-100"
  >
    <lottie-animation
      ref="anim"
      :animationData="animationData"
      :speed="1"
      :loop="true"
      :autoPlay="true"
      :style="{ width: '100% !important', height: '100dvh !important' }"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";

import animationJson from "@/assets/animations/goplan_loading.json";

export default {
  name: "GlobalLoadingAnimation",
  components: {
    LottieAnimation,
  },
  props: {
    name: {
      type: String,
      default: "Global",
    },
  },

  data() {
    return {
      showDialog: true,
      animationData: JSON.parse(JSON.stringify(animationJson)),
      timer: null,
    };
  },
  mounted: function () {
    this.timer = setInterval(() => {
      if (this.meReady) {
        clearInterval(this.timer);
        this.showDialog = false;
        this.$refs.anim.stop();
      }
    }, 2000);
  },

  computed: {
    ...mapGetters({
      meReady: "users/meReady",
    }),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  background: white;
}
</style>
