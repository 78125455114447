import axios from "@/axios";

const mutations = {
  setAll(state, collection) {
    state.masterProjects = collection;
  },
  push(state, item) {
    state.masterProjects = [...state.masterProjects, item];
  },
};

const actions = {
  getAll: function ({ commit }, scope = "user") {
    const url =
      scope === "user"
        ? `/v1/users/me/master_projects`
        : `/v1/companies/me/master_projects`;
    return axios.get(url).then((response) => {
      commit("setAll", response.data);
    });
  },
};

const getters = {
  all: (state) => state.masterProjects,
  allActive: (state) =>
    state.masterProjects?.filter((p) => p.phases.some((phase) => !phase.done)),
  templates: (state) => state.masterProjects?.filter((p) => p.template),
  hasLatePhase: (state) => {
    if (!state.masterProjects) return false;
    for (const project of state.masterProjects) {
      for (const phase of project.phases) {
        if (phase.late) return true; // Found a late phase, return true
      }
    }
    return false; // No late phases found
  },
};

// =====================================================================
const getDefaultState = () => {
  return {
    masterProjects: null,
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
