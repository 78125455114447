import axios from "@/axios";

const isLoadingOnlyOne = (value) => {
  return {
    ...getters.isLoading,
    ...value,
  };
};

const mutations = {
  setAll(state, companies) {
    state.all = companies;
  },
  addOne(state, company) {
    state.all.push(company);
  },
  update(state, newCompany) {
    const index = state.all.findIndex(
      (currentCompany) => currentCompany.id === newCompany.id,
    );
    if (index !== -1) state.all.splice(index, 1, newCompany);
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  getAll({ commit }) {
    axios.get("companies").then((response) => {
      commit("setAll", response.data);
    });
  },
  add({ commit, getters }, company) {
    commit("setIsLoading", isLoadingOnlyOne({ addOrUpdate: true }));
    axios
      .post("companies", { company: company })
      .then((response) => commit("addOne", response.data))
      .then(() => {
        commit("setIsLoading", isLoadingOnlyOne({ addOrUpdate: false }));
      });
  },
  update({ commit }, company) {
    commit("setIsLoading", isLoadingOnlyOne({ addOrUpdate: true }));
    axios
      .put(`companies/${company.id}`, { company: company })
      .then((response) => commit("update", response.data))
      .then(() => {
        commit("setIsLoading", isLoadingOnlyOne({ addOrUpdate: false }));
      });
  },
  toggleActiveStatus({ commit }, { companyID, newActiveStatus }) {
    const status = newActiveStatus ? "active" : "inactive";
    commit("setIsLoading", isLoadingOnlyOne({ status: true }));
    return axios
      .put(`companies/${companyID}/${status}`)
      .then((response) => commit("update", response.data))
      .then(() => {
        commit("setIsLoading", isLoadingOnlyOne({ status: false }));
      });
  },
  togglePlan({ commit }, { companyID, newPlan }) {
    const plan = newPlan ? "premium" : "freemium";
    commit("setIsLoading", isLoadingOnlyOne({ plan: true }));
    return axios
      .put(`companies/${companyID}`, { company: { plan } })
      .then((response) => commit("update", response.data))
      .then(() => {
        commit("setIsLoading", isLoadingOnlyOne({ status: false }));
      });
  },
};

const getters = {
  all: (state) => state.all,
  isLoading: (state) => state.isLoading,
};

const state = () => ({
  all: [],
  isLoading: {
    addOrUpdate: false,
    status: false,
    plan: false,
  },
});

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
