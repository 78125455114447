<template>
  <v-navigation-drawer
    ref="menuDrawer"
    app
    hide-overlay
    mini-variant-width="56"
    :mini-variant="!isSomeDrawerOpen"
    permanent
    style="z-index: 200"
    :width="
      $vuetify.breakpoint.mobile
        ? '100%'
        : navigation.width
        ? navigation.width.replace('px', '')
        : 500
    "
  >
    <div class="d-flex fill-height">
      <Menu style="min-width: 56px" />
      <GoplanDrawers class="flex-grow-1" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
const DEFAULT_SIDEBAR_WIDTH = "500px";
const MIN_SIDEBAR_WIDTH = "400px";

export default {
  name: "Sidebar",

  components: {
    GoplanDrawers: () => import("@/views/goplan-components/GoplanDrawers"),
    Menu: () => import("@/components/Menu"),
  },

  mounted() {
    if (this.$refs.menuDrawer) {
      this.navigation.width = this.menuSidebarWidth || DEFAULT_SIDEBAR_WIDTH;
      this.setBorderWidth();
      this.setEvents();
    }
  },

  data: () => ({
    navigation: {
      shown: false,
      width: null,
      borderSize: 3,
    },
  }),

  watch: {
    isSomeDrawerOpen() {
      if (this.$refs.menuDrawer) {
        this.navigation.width = this.menuSidebarWidth || DEFAULT_SIDEBAR_WIDTH;
        this.setBorderWidth();
        this.setEvents();
      }
    },
    navigationWidth: function (newVal, oldVal) {
      if (oldVal && newVal && newVal !== oldVal) {
        this.updateSidebarWidthUserSettings(newVal);
        this.setBorderWidth();
        this.setEvents();
      }
    },
  },

  methods: {
    sidebarWidthOrMinSidebarWidth(sidebarWidth) {
      const numericSidebarWidth = parseFloat(sidebarWidth);
      const numericMinSidebarWidth = parseFloat(MIN_SIDEBAR_WIDTH);
      return numericSidebarWidth < numericMinSidebarWidth
        ? MIN_SIDEBAR_WIDTH
        : sidebarWidth;
    },
    setBorderWidth() {
      let i = this.$refs.menuDrawer.$el.querySelector(
        ":scope > .v-navigation-drawer__border",
      );
      i.style.width = this.isSomeDrawerOpen
        ? this.navigation.borderSize + "px"
        : "0px";
      i.style.cursor = this.isSomeDrawerOpen ? "ew-resize" : "none";
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.menuDrawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        let fpx = f + "px";
        el.style.width = vm.sidebarWidthOrMinSidebarWidth(fpx);
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
            e.preventDefault();
          }
        },
        false,
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = this.sidebarWidthOrMinSidebarWidth(
            el.style.width,
          );
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false,
      );
    },
    updateSidebarWidthUserSettings: function (menuSidebarWidth) {
      if (["56px", this.menuSidebarWidth].includes(menuSidebarWidth)) return;
      if (this.$vuetify.breakpoint.mobile) return;
      this.$store.dispatch("users/updateMeAppSettings", {
        key: "menuSidebarWidth",
        value: menuSidebarWidth
          ? this.sidebarWidthOrMinSidebarWidth(menuSidebarWidth)
          : this.menuSidebarWidth,
      });
    },
  },

  computed: {
    ...mapGetters({
      isSomeDrawerOpen: "isSomeDrawerOpen",
      menuSidebarWidth: "users/menuSidebarWidth",
    }),

    direction() {
      return this.navigation.shown === false ? "Open" : "Closed";
    },

    navigationWidth() {
      return this.navigation.width;
    },
  },
};
</script>
