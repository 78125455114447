<template>
  <v-card class="border-radius-xl" style="overflow: hidden">
    <v-text-field
      v-if="models[selectedModelID].searchable !== false"
      autofocus
      :loading="loading"
      loader-height=".2em"
      flat
      filled
      dense
      class="bigger-input"
      height="64px"
      hide-details
      v-model="keyword"
      placeholder="Search..."
      solo
      style="margin-top: 0px !important"
      @input="beforeSearch()"
      @keydown.esc.stop="escapeHandler"
      @click.stop=""
    >
      <v-icon
        :size="16"
        v-show="keyword"
        slot="append"
        color="secondary"
        v-text="'mdi-close-circle'"
        @click.stop="resetSearch"
      />
    </v-text-field>
    <!-- --------------------------------------------------------------------- -->
    <!-- END search input                                                      -->
    <!-- --------------------------------------------------------------------- -->
    <v-divider />
    <!-- --------------------------------------------------------------------- -->
    <!-- BEGIN models                                                          -->
    <!-- --------------------------------------------------------------------- -->
    <v-tabs
      v-if="models.length > 1"
      class="border-bottom px-4"
      v-model="selectedModelID"
      mandatory
      background-color="transparent"
      :key="`tab-${selectedModel.name}-${selectedScoper.scope}`"
    >
      <v-tab
        v-for="(model, i) in models"
        v-show="model.name != 'Project' || selectedScoper.canSearchProject"
        :key="'model-' + i"
        :class="$classInput"
        class="font-weight-bolder"
        v-text="
          model.translation ||
          $t('ui.dictionary.' + $options.filters.toSnakeCase(model.name))
        "
        @change="beforeSearch(0)"
        @click.stop=""
      />
    </v-tabs>
    <!-- --------------------------------------------------------------------- -->
    <!-- END models                                                            -->
    <!-- --------------------------------------------------------------------- -->
    <!-- BEGIN scopers                                                         -->
    <!-- --------------------------------------------------------------------- -->
    <div
      v-if="selectedModel.searchable"
      class="d-flex justify-space-between align-center px-4 py-2"
      :style="{ gap: '5rem' }"
    >
      <span :class="$classInput" v-text="$t('ui.dictionary.results')" />
      <div class="d-flex align-center" :style="{ gap: '0.5rem' }">
        <span :class="$classInput" v-text="$t('ui.dictionary.search')" />
        <v-chip
          v-for="(scoper, i) in scopers"
          :key="'scopers-' + i"
          @click.stop="
            () => {
              selectedScoper = scoper;
              beforeSearch(0);
            }
          "
          v-text="scoper.translation"
          :outlined="selectedScoper.scope !== scoper.scope"
          small
          color="secondary"
          label
        />
      </div>
    </div>
    <!-- --------------------------------------------------------------------- -->
    <!-- END scopers                                                           -->
    <!-- --------------------------------------------------------------------- -->
    <v-divider />
    <!-- --------------------------------------------------------------------- -->
    <!-- BEGIN search loading animation                                        -->
    <!-- --------------------------------------------------------------------- -->
    <SearchLoadingAnimation v-show="loading" />
    <!-- --------------------------------------------------------------------- -->
    <!-- END search loading animation                                          -->
    <!-- --------------------------------------------------------------------- -->
    <!-- BEGIN results                                                         -->
    <!-- --------------------------------------------------------------------- -->
    <v-card-text
      v-if="selectedResults && selectedResults.length > 0"
      class="pa-0"
      style="max-height: 400px"
    >
      <v-list dense>
        <v-list-item-group color="primary" max="0">
          <v-list-item
            v-for="(result, i) in selectedResults"
            :key="'result-' + i"
            dense
            @click="redirectOrActionHandler(result)"
          >
            <v-hover v-slot="{ hover }">
              <div class="d-flex align-center">
                <v-list-item-avatar @click.stop="">
                  <v-img
                    v-if="result.avatarUrl"
                    :alt="result.avatarUrl"
                    :src="result.avatarUrl"
                    class="border-radius-round"
                  />
                  <v-img
                    v-else-if="result.logoUrl"
                    :alt="result.logoUrl"
                    :src="result.logoUrl"
                    class="border-radius-round"
                  />
                  <FileDataTable
                    v-else-if="result.file"
                    globalType="file"
                    :file="result.file"
                  />
                  <v-icon
                    v-else
                    :size="24"
                    v-text="$icons[$options.filters.toCamelCase(result.type)]"
                    :color="hover ? 'primary' : null"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="
                      white-space-pre-line
                      font-weight-bold
                      placeholder-light
                      text-sm
                    "
                    v-text="result.title"
                  />
                  <v-list-item-subtitle
                    class="white-space-pre-line font-weight-light"
                    v-text="result.subtitle"
                  />
                </v-list-item-content>
              </div>
            </v-hover>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-text
      v-else-if="selectedModel.items && selectedModel.items.length === 0"
    >
      <div class="empty-data d-flex flex-column pa-2">
        <span v-text="selectedModel.empty.message" />
        <v-btn
          v-if="selectedModel.empty.button"
          color="secondary"
          dark
          v-text="selectedModel.empty.button.text"
          :to="selectedModel.empty.button.link"
        />
      </div>
    </v-card-text>
    <div v-else-if="results !== null">
      <not-found :text="$t('ui.not_found.search_no_result')" />
    </div>
  </v-card>
</template>
<script>
import { first } from "lodash";

export default {
  props: {
    openResultOnClick: Boolean,
    excludedScopers: {
      type: Array,
      default: () => [],
    },
    models: {
      type: Array,
      default: () => [],
      required: true,
    },
    scoperIds: {
      type: Object,
      default: () => ({}),
    },
    excludedResults: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    FileDataTable: () =>
      import("@/views/goplan-components/Files/FileDataTable"),
    NotFound: () => import("@/views/goplan-components/NotFound"),
    SearchLoadingAnimation: () =>
      import("@/views/goplan-components/Animations/SearchLoadingAnimation"),
  },

  data: () => ({
    defaultLimitSearch: 20,
    delayTriggerSearch: 500,
    checksum: 0,
    loading: false,
    keyword: "",
    selectedScoper: null,
    selectedModelID: 0,
    results: null,
  }),

  methods: {
    resetSearch() {
      this.keyword = "";
      this.results = null;
    },
    redirectOrActionHandler(result) {
      this.$emit("clickOnResult", result);

      if (!this.openResultOnClick) return;
      if (result.action) {
        this.actionHandler(result.action);
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            objectType: result.type,
            objectID: result.id,
            roomID: null,
          },
        });
      }
      this.$store.commit("toggleSearch", false);
    },
    beforeSearch: function (delayTriggerSearch = 500) {
      if (this.keyword.length < 3) return;

      this.results = null;
      this.checksum++;
      this.loading = true;
      setTimeout(this.triggerSearch, delayTriggerSearch, this.checksum);
    },
    triggerSearch: function (localChecksum) {
      if (
        localChecksum != this.checksum ||
        this.selectedModel.searchable === false
      ) {
        return (this.loading = false);
      }
      const keyword = encodeURIComponent(this.keyword);
      const scope = this.selectedScoper.scope;
      const scopeId = this.getScopeID(scope);
      const model = this.selectedModel.name;
      const conditions = this.selectedModel.conditions;
      const limit = this.defaultLimitSearch;
      const params = { keyword, scope, scopeId, limit, model, conditions };

      axios
        .get("/v2/search", { params })
        .then((response) => (this.results = response.data))
        .then(() => (this.loading = false));
    },
    getScopeID: function (scope) {
      switch (scope) {
        case "phaseId":
          return (
            this.scoperIds.phaseId ?? this.$store.getters["current/phaseID"]
          );
        default:
          return this.$store.getters["users/myCompany"].id;
      }
    },
    actionHandler: function (action) {
      switch (action.name) {
        case "open_room":
          this.openRoomHandler(action.parameters);
          break;
        case "open_link":
          this.$router.push(action.link);
          break;
        default:
          return;
      }
    },
    openRoomHandler: function (parameters) {
      if (!parameters.roomId) return;

      const roomId = parameters.roomId;
      const sameRoom = roomId === this.$store.getters["chats/selectedRoomID"];
      // TODO: fix open channel
      // this.$store.dispatch("chats/selectRoomID", roomId);
      this.$store.dispatch("chats/toggleShowChats", {
        isAddChatDialog: false,
        value: !sameRoom,
      });
    },
    escapeHandler: function () {
      if (this.keyword) {
        this.keyword = "";
        this.results = [];
      } else {
        this.$store.commit("toggleSearch", false);
      }
    },
  },

  created() {
    this.selectedScoper = first(this.scopers);
  },

  computed: {
    selectedResults() {
      const modelName = this.selectedModel?.name;

      const searchedResults =
        modelName && this.results ? this.results[modelName] : [];

      const results = [...searchedResults, ...this.itemsSelectedModel];

      const excludedModelItems = this.excludedResults.find(
        (item) => item.model === modelName,
      );
      const excludedItemIds = excludedModelItems
        ? excludedModelItems.itemIds
        : [];

      const filteredResults = results.filter(
        (result) => !excludedItemIds.includes(result.id),
      );

      return filteredResults;
    },
    selectedModel() {
      return this.models[this.selectedModelID];
    },
    itemsSelectedModel() {
      return this.selectedModel?.items ? this.selectedModel.items : [];
    },
    scopers() {
      const scopers = [];

      if (!this.excludedScopers.includes("companyId")) {
        scopers.push({
          translation: this.$t("view.search.scoper.my_company"),
          scope: "companyId",
          canSearchProject: true,
        });
      }

      if (
        this.getScopeID("phaseId") &&
        !this.excludedScopers.includes("phaseId")
      ) {
        scopers.push({
          translation: this.$t("view.search.scoper.current_phase"),
          scope: "phaseId",
          canSearchProject: false,
        });
      }

      return scopers;
    },
  },
};
</script>
<style>
.v-select.fit {
  width: min-content;
}

.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}

.list-item-action-horizontal {
  flex-direction: row;
  align-items: center;
}
</style>
