<template>
  <span>
    <template v-if="auth">
      <v-footer color="transparent" class="mt-10 pt-10 pb-5 pb-16">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" lg="8" class="mb-2 mx-auto text-center">
                <a
                  v-for="item in footer"
                  :key="item.linkName"
                  :href="item.link"
                  class="text-decoration-none text-secondary ls-0 mx-4 text-md"
                  target="_blank"
                  >{{ $t(item.linkName) }}</a
                >
              </v-col>
              <v-col cols="8" class="mx-auto text-center">
                <v-btn
                  v-for="socialMedia in socialMedias"
                  :key="socialMedia.icon"
                  icon
                  width="31"
                  :ripple="false"
                  class="mx-3 text-secondary no-default-hover"
                  link
                  :href="socialMedia.to"
                  target="_blank"
                >
                  <v-icon size="18">{{ socialMedia.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" class="mx-auto text-center">
                <p class="copyright text-secondary text-md ls-0">
                  Copyright © {{ new Date().getFullYear() }}
                  Soft by
                  <a
                    href="https://www.goplan.ch"
                    class="text-decoration-none btn-hover text-primary text-body-2 ls-0"
                    target="_blank"
                    >Goplan</a
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>

    <template v-else>
      <v-footer color="transparent" class="px-6 pb-16">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="12" class="d-flex justify-end">
                <ul
                  v-for="item in footer"
                  :key="item.linkName"
                  class="d-flex list-style-none"
                >
                  <li>
                    <a
                      :href="item.link"
                      class="text-decoration-none text-muted text-body-2 ls-0 btn-dark-hover no-default-hover"
                      target="_blank"
                      >{{ $t(item.linkName) }}</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Goplan.ch",
          link: "https://www.goplan.ch/",
        },
        {
          linkName: "Blog",
          link: "https://www.goplan.ch/blog/",
        },
        {
          linkName: "view.legal_mention.title",
          link: "/legalMention",
        },
      ],
      socialMedias: [
        {
          icon: "fab fa-facebook",
          to: "https://www.facebook.com/goplan.ch",
        },
        {
          icon: "fab fa-instagram",
          to: "https://www.instagram.com/goplan_ch",
        },
        {
          icon: "fab fa-linkedin",
          to: "https://il.linkedin.com/company/goplan-ch",
        },
      ],
    };
  },
};
</script>
