/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
 */

import Vue from "vue";
import Clipboard from "v-clipboard";
import ActionCableVue from "actioncable-vue";
import Photoswipe from "vue-pswipe";

import App from "./App.vue";
import axios from "@/axios";
import DashboardPlugin from "./plugins/dashboard-plugin";
import i18n from "./i18n";
import router from "./router";
import store from "./store/store";
import VueHotkey from "v-hotkey";
import vuetify from "./plugins/vuetify";
import { fileMixin } from "./mixins/file.js";

window.axios = axios;

// Just some code to remember that exists but not useful now
// window.__ = function (value, replace = {}) {
//     return store.getters.__(value, replace);
// };

// skipcq: JS-0057
window.console.logDebug = function () {};

// Pretty cool we can give to all our Vue components a function that is global and can be called in all our components
// Vue.prototype.__ = window.__;
// Vue.prototype.helper = window.helper;

Vue.use(ActionCableVue, {
  debug: true,
  connectImmediately: true,
  connectionUrl: process.env.VUE_APP_WS_URL,
});
Vue.use(Clipboard);
Vue.use(DashboardPlugin);
Vue.use(Photoswipe);
Vue.use(VueHotkey);
Vue.config.productionTip = false;
Vue.mixin(fileMixin);
Vue.prototype.$fileMixin = fileMixin;

Vue.filter("truncate", function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
});

Vue.filter("parseIntOrNull", function (value) {
  return value ? parseInt(value, 10) : null;
});

Vue.filter("toSnakeCase", function (value) {
  return value
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
});

Vue.filter("toCamelCase", function (value) {
  if (typeof value !== "string") return;
  // Replace hyphens and underscores with spaces
  value = value.replace(/[-_]/g, " ");

  // Split the string into words
  var words = value.split(" ");

  // Convert all words to lowercase except the first one
  for (var i = 1; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  // Convert the first word to lowercase if it exists
  if (words.length > 0) {
    words[0] = words[0].charAt(0).toLowerCase() + words[0].slice(1);
  }

  // Join the words together without spaces
  return words.join("");
});

Vue.filter("filterOut", function (value, keysToReject) {
  const keys = Object.keys(value);
  return keys
    .filter((key) => !keysToReject.includes(key))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: value[key],
      });
    }, {});
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("fullTrim", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.replace(/\s+/g, " ").trim();
});

Vue.filter("formatDate", function (value) {
  if (!value) return "";

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(value);
  const locale = i18n.locale;

  return date.toLocaleString(locale, options);
});

Vue.filter("formatShortDate", function (value) {
  if (!value) return "";

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const date = new Date(value);
  const locale = i18n.locale;

  return date.toLocaleString(locale, options);
});

/*
 * Pass as parameter in the filter function of an array
 * Get the unique element of the array
 * example: ARRAY.filter(this.$options.filters.unique)
 */
Vue.filter("unique", function (value, index, self) {
  return self.indexOf(value) === index;
});

Vue.prototype.$getSvgIconFromFilename = (filename) => {
  const type = filename ? fileMixin.getExtension(filename) : "none";

  const svgName = Vue.prototype.$svgs[type] || Vue.prototype.$svgs["unknown"];
  var images = require.context("@/assets/icons/", true, /\.svg/);
  return images("./" + svgName);
};

Vue.prototype.$getMP3 = (filename) => {
  if (!filename) return;
  var sounds = require.context("@/assets/sounds/", true, /\.mp3/);
  return sounds("./" + filename + ".mp3");
};

// --------------------------------------------------------------------
// Global group of class for the design
// --------------------------------------------------------------------
Vue.prototype.$objectShowViewKeys = [
  "objectType",
  "objectID",
  "objectTab",
  "roomID",
];

Vue.prototype.$svgs = {
  none: "svg-icon-none-square.svg",
  unknown: "svg-icon-attachment-square.svg",
  pdf: "svg-icon-pdf-square.svg",
  doc: "svg-icon-word-square.svg",
  docx: "svg-icon-word-square.svg",
  numbers: "svg-icon-excel-square.svg",
  xls: "svg-icon-excel-square.svg",
  xlsx: "svg-icon-excel-square.svg",
  zip: "svg-icon-zip-square.svg",
  dwg: "svg-icon-dwg-square.svg",
  dxf: "svg-icon-dwg-square.svg",
};

Vue.prototype.$icons = {
  add: "mdi-plus",
  addAttachment: "mdi-attachment-plus",
  addThick: "mdi-plus-thick",
  applications: "mdi-apps",
  approval: "mdi-thumb-up",
  approvalProcess: "mdi-thumb-up",
  approvalProcessStep: "mdi-thumb-up",
  approvalProcessStepUser: "mdi-thumb-up",
  approved: "mdi-check-bold",
  automatically_approved: "mdi-check-bold",
  manually_approved: "mdi-check-bold",
  archive: "mdi-archive",
  archived: "mdi-archive",
  archive_off: "mdi-archive-off",
  arrowDown: "mdi-arrow-down-bold",
  arrowRight: "mdi-arrow-right-bold",
  arrowSelect: "mdi-menu-down",
  arrowSelectCircle: "mdi-arrow-down-drop-circle",
  attachment: "mdi-attachment",
  avatar: "mdi-account-circle",
  back: "mdi-chevron-left",
  calendar: "mdi-calendar",
  canceled: "mdi-cancel",
  changesRequested: "mdi-exclamation-thick",
  changes_requested: "mdi-exclamation-thick",
  channel: "mdi-message",
  close: "mdi-close",
  closeThick: "mdi-close-thick",
  collapse: "mdi-arrow-collapse",
  colleagueNetwork: "mdi-account-network",
  company: "mdi-store",
  currentProject: "mdi-folder-open",
  deadline: "mdi-calendar-alert",
  delete: "mdi-trash-can-outline",
  deleted: "mdi-trash-can-outline",
  document: "mdi-text-box",
  documentType: "mdi-folder-file",
  done: "mdi-check-bold",
  dots: "mdi-dots-horizontal",
  download: "mdi-download",
  duplicate: "mdi-content-duplicate",
  edit: "mdi-pencil",
  error: "mdi-close-thick",
  expand: "mdi-arrow-expand",
  fastPdf: "mdi-lightning-bolt",
  filter: "mdi-filter-variant",
  gift: "mdi-gift",
  group: "mdi-format-list-group",
  history: "mdi-history",
  inactive: "mdi-circle-edit-outline",
  inProgress: "mdi-circle-edit-outline",
  info: "mdi-information",
  kanban: "mdi-view-column",
  lapsed: "mdi-close-thick",
  late: "mdi-clock-alert",
  list: "mdi-view-list",
  lock: "mdi-lock",
  mapMarker: "mdi-map-marker-radius",
  masterProject: "mdi-folder-multiple",
  me: "mdi-account",
  minusThick: "mdi-minus-thick",
  notification: "mdi-bell",
  ongoing: "mdi-circle-edit-outline",
  open: "mdi-chevron-right",
  openedGift: "mdi-gift-open",
  options: "mdi-dots-vertical",
  partnerNetwork: "mdi-folder-network",
  pdf: "mdi-file-pdf-box",
  pending: "mdi-circle-edit-outline",
  phase: "mdi-folder",
  photo: "mdi-camera",
  project: "mdi-folder",
  publicLink: "mdi-send",
  raise: "mdi-exclamation-thick",
  relations: {
    blocking: "mdi-minus-circle",
    relations: "mdi-sitemap",
    simple: "mdi-link-variant",
    waitingFor: "mdi-hand-back-left",
  },
  reload: "mdi-reload",
  reminder: "mdi-reminder",
  roleGroup: "mdi-account-group",
  room: "mdi-message",
  search: "fas fa-search",
  share: "mdi-export-variant",
  sharebox: "mdi-zip-box",
  shareBox: "mdi-zip-box",
  settings: "mdi-cog",
  subDirectory: "mdi-subdirectory-arrow-right",
  tags: "mdi-tag",
  task: "mdi-check-bold",
  template: "mdi-auto-fix",
  todo: "mdi-pause",
  trash: "mdi-trash-can-outline",
  trashFull: "mdi-trash-can",
  unarchive: "mdi-inbox-arrow-up",
  undelete: "mdi-trash-can-outline",
  unreadNotification: "mdi-bell-ring",
  upload: "mdi-tray-arrow-up",
};

Vue.prototype.$colors = {
  todo: "grey",
  toDo: "grey",
  to_do: "grey",
  pending: "info",
  ongoing: "info",
  on_going: "info",
  onGoing: "info",
  in_progress: "info",
  inProgress: "info",
  done: "success",
  approved: "success",
  manually_approved: "success",
  manuallyApproved: "success",
  automatically_approved: "success",
  automaticallyApproved: "success",
  archive: "error",
  archived: "error",
  delete: "error",
  deleted: "error",
  canceled: "error",
  changesRequested: "error",
  changes_requested: "error",
  lapsed: "error",
  approving: "primary",
  deliverable: "success",
  delivered: "success",
};

Vue.prototype.$statusBGClasses = {
  in_progress: "bg-gradient-info text-white",
  inProgress: "bg-gradient-info text-white",
  changesRequested: "bg-gradient-danger text-white",
  changes_requested: "bg-gradient-danger text-white",
  approved: "bg-gradient-success text-white",
  canceled: "text-danger",
  to_do: "bg-gradient-light text-dark",
  on_going: "bg-gradient-info text-white",
  approving: "bg-gradient-primary text-white",
  archived: "bg-gradient-danger text-white",
  deliverable: "bg-gradient-success text-white",
  delivered: "bg-gradient-success text-white",
};

Vue.prototype.$colorDefault = "#3a416ff2";

Vue.prototype.$classAbsoluteTopCenter =
  "position-absolute top-0 left-0 right-0 ml-auto mr-auto text-center";

Vue.prototype.$classBtnGoplan =
  "font-weight-bold text-xs btn-default bg-gradient-goplan py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedGoplan =
  "font-weight-bold text-xs btn-outline-goplan py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnDefault =
  "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedDefault =
  "font-weight-bold text-xs btn-outline-secondary py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnPrimary =
  "font-weight-bold text-xs btn-default bg-gradient-primary py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedPrimary =
  "font-weight-bold text-xs btn-outline-primary py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnWarning =
  "font-weight-bold text-xs btn-default bg-gradient-warning py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedWarning =
  "font-weight-bold text-xs btn-outline-warning py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnDanger =
  "font-weight-bold text-xs btn-default bg-gradient-danger py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedDanger =
  "font-weight-bold text-xs btn-outline-danger py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnSuccess =
  "font-weight-bold text-xs btn-default bg-gradient-success py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedSuccess =
  "font-weight-bold text-xs btn-outline-success py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnInfo =
  "font-weight-bold text-xs btn-default bg-gradient-info py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedInfo =
  "font-weight-bold text-xs btn-outline-info py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnSecondary =
  "font-weight-bold text-xs btn-default bg-gradient-secondary py-5 px-6 my-2 me-2";
Vue.prototype.$classBtnOutlinedSecondary =
  "font-weight-bold text-xs btn-outline-secondary py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnPremium =
  "font-weight-bold text-xs btn-default bg-gradient-purple py-5 px-6 my-2 me-2";

Vue.prototype.$classBtnTextSecondary =
  "text-secondary text-uppercase align-center";
Vue.prototype.$classBtnTextDanger = "text-danger text-uppercase align-center";

Vue.prototype.$classInput = " text-xs font-weight-bold placeholder-light";
Vue.prototype.$classInputOutlined =
  " text-xs font-weight-bold placeholder-light border border-radius-md";
Vue.prototype.$classLabel = "text-xs font-weight-bolder";
Vue.prototype.$classLabelDisabled =
  "text-xs font-weight-bolder ms-1 grey--text text--lighten-1";
Vue.prototype.$classCardShadow = "card-shadow border-radius-xl px-4 py-5";

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
