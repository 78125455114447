var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"border-radius-xl",staticStyle:{"overflow":"hidden"}},[(_vm.models[_vm.selectedModelID].searchable !== false)?_c('v-text-field',{staticClass:"bigger-input",staticStyle:{"margin-top":"0px !important"},attrs:{"autofocus":"","loading":_vm.loading,"loader-height":".2em","flat":"","filled":"","dense":"","height":"64px","hide-details":"","placeholder":"Search...","solo":""},on:{"input":function($event){return _vm.beforeSearch()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.escapeHandler.apply(null, arguments)},"click":function($event){$event.stopPropagation();}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.keyword),expression:"keyword"}],attrs:{"slot":"append","size":16,"color":"secondary"},domProps:{"textContent":_vm._s('mdi-close-circle')},on:{"click":function($event){$event.stopPropagation();return _vm.resetSearch.apply(null, arguments)}},slot:"append"})],1):_vm._e(),_c('v-divider'),(_vm.models.length > 1)?_c('v-tabs',{key:("tab-" + (_vm.selectedModel.name) + "-" + (_vm.selectedScoper.scope)),staticClass:"border-bottom px-4",attrs:{"mandatory":"","background-color":"transparent"},model:{value:(_vm.selectedModelID),callback:function ($$v) {_vm.selectedModelID=$$v},expression:"selectedModelID"}},_vm._l((_vm.models),function(model,i){return _c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(model.name != 'Project' || _vm.selectedScoper.canSearchProject),expression:"model.name != 'Project' || selectedScoper.canSearchProject"}],key:'model-' + i,staticClass:"font-weight-bolder",class:_vm.$classInput,domProps:{"textContent":_vm._s(
        model.translation ||
        _vm.$t('ui.dictionary.' + _vm.$options.filters.toSnakeCase(model.name))
      )},on:{"change":function($event){return _vm.beforeSearch(0)},"click":function($event){$event.stopPropagation();}}})}),1):_vm._e(),(_vm.selectedModel.searchable)?_c('div',{staticClass:"d-flex justify-space-between align-center px-4 py-2",style:({ gap: '5rem' })},[_c('span',{class:_vm.$classInput,domProps:{"textContent":_vm._s(_vm.$t('ui.dictionary.results'))}}),_c('div',{staticClass:"d-flex align-center",style:({ gap: '0.5rem' })},[_c('span',{class:_vm.$classInput,domProps:{"textContent":_vm._s(_vm.$t('ui.dictionary.search'))}}),_vm._l((_vm.scopers),function(scoper,i){return _c('v-chip',{key:'scopers-' + i,attrs:{"outlined":_vm.selectedScoper.scope !== scoper.scope,"small":"","color":"secondary","label":""},domProps:{"textContent":_vm._s(scoper.translation)},on:{"click":function($event){$event.stopPropagation();return (function () {
            _vm.selectedScoper = scoper;
            _vm.beforeSearch(0);
          }).apply(null, arguments)}}})})],2)]):_vm._e(),_c('v-divider'),_c('SearchLoadingAnimation',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]}),(_vm.selectedResults && _vm.selectedResults.length > 0)?_c('v-card-text',{staticClass:"pa-0",staticStyle:{"max-height":"400px"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","max":"0"}},_vm._l((_vm.selectedResults),function(result,i){return _c('v-list-item',{key:'result-' + i,attrs:{"dense":""},on:{"click":function($event){return _vm.redirectOrActionHandler(result)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-list-item-avatar',{on:{"click":function($event){$event.stopPropagation();}}},[(result.avatarUrl)?_c('v-img',{staticClass:"border-radius-round",attrs:{"alt":result.avatarUrl,"src":result.avatarUrl}}):(result.logoUrl)?_c('v-img',{staticClass:"border-radius-round",attrs:{"alt":result.logoUrl,"src":result.logoUrl}}):(result.file)?_c('FileDataTable',{attrs:{"globalType":"file","file":result.file}}):_c('v-icon',{attrs:{"size":24,"color":hover ? 'primary' : null},domProps:{"textContent":_vm._s(_vm.$icons[_vm.$options.filters.toCamelCase(result.type)])}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"\n                    white-space-pre-line\n                    font-weight-bold\n                    placeholder-light\n                    text-sm\n                  ",domProps:{"textContent":_vm._s(result.title)}}),_c('v-list-item-subtitle',{staticClass:"white-space-pre-line font-weight-light",domProps:{"textContent":_vm._s(result.subtitle)}})],1)],1)]}}],null,true)})],1)}),1)],1)],1):(_vm.selectedModel.items && _vm.selectedModel.items.length === 0)?_c('v-card-text',[_c('div',{staticClass:"empty-data d-flex flex-column pa-2"},[_c('span',{domProps:{"textContent":_vm._s(_vm.selectedModel.empty.message)}}),(_vm.selectedModel.empty.button)?_c('v-btn',{attrs:{"color":"secondary","dark":"","to":_vm.selectedModel.empty.button.link},domProps:{"textContent":_vm._s(_vm.selectedModel.empty.button.text)}}):_vm._e()],1)]):(_vm.results !== null)?_c('div',[_c('not-found',{attrs:{"text":_vm.$t('ui.not_found.search_no_result')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }