// =====================================================================
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  updateParams(state, params) {
    state.params = params;
    const currentResourcesID = [
      {
        resource: "phase",
        id: params.phaseId,
      },
    ];
    currentResourcesID.forEach((currentResourceID) => {
      this.commit("current/setCurrentResourceID", currentResourceID);
    });
  },
  updateQuery(state, queryParameters) {
    state.query = queryParameters;
    this.dispatch("chats/selectRoomID", queryParameters.roomID);
  },
};

// =====================================================================
const getters = {
  currentQuery: (state) => (desiredParameters) => {
    return Object.keys(state.query)
      .filter((key) => desiredParameters.includes(key))
      .reduce((obj, key) => {
        obj[key] = state.query[key];
        return obj;
      }, {});
  },
};

// =====================================================================
const getDefaultState = () => {
  return {
    params: {},
    query: {},
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
