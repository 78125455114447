import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";

import alerts from "./modules/alerts";
import authentications from "./modules/authentications";
import chats from "./modules/chats";
import companies from "./modules/companies";
import companyRegistration from "./modules/companyRegistration";
import current from "./modules/current";
import geojson from "./modules/geojson";
import masterProjects from "./modules/masterProjects";
import objectsNavigation from "./modules/objectsNavigation";
import parameters from "./modules/parameters";
import photos from "./modules/photos";
import premium from "./modules/premium";
import sounds from "./modules/sounds";
import users from "./modules/users";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    alerts,
    authentications,
    chats,
    companies,
    companyRegistration,
    current,
    geojson,
    masterProjects,
    objectsNavigation,
    photos,
    parameters,
    premium,
    sounds,
    users,
  },
  mutations: {
    toggleCreationModule(state, value) {
      state.displayCreationModule =
        value == null ? !state.displayCreationModule : value;
    },
    toggleSearch(state, value) {
      state.displaySearch = value == null ? !state.displaySearch : value;
    },
    userConfirmationAction(state, data) {
      state.userConfirmationAction = data;
    },
    // Don't give the value to simply toggle the drawer
    toggleDrawer(state, { drawer, value }) {
      const shouldOpen = [undefined, null].includes(value)
        ? !state.drawers[drawer]
        : value;
      this.commit("closeAllDrawers");
      state.drawers[drawer] = shouldOpen;
    },
    closeAllDrawers(state) {
      for (const drawer in state.drawers) {
        state.drawers[drawer] = false;
      }
    },
  },

  actions: {
    displayCreationModule({ commit }) {
      commit("toggleCreationModule");
    },
    displaySearch({ commit }) {
      commit("toggleSearch");
    },
  },

  getters: {
    displaySearch: (state) => state.displaySearch,
    displayCreationModule: (state) => state.displayCreationModule,
    userConfirmationAction: (state) => state.userConfirmationAction,
    getLocale: () => {
      const locale = "en";
      const locales = {
        en: "en_US",
        fr: "fr_FR",
        de: "de_DE",
        it: "it_IT",
      };
      return locales[i18n.locale ?? locale];
    },
    // BEGIN drawers
    isSomeDrawerOpen: (state) =>
      Object.values(state.drawers).some((value) => value === true),
    displayMasterProjectDrawer: (state) => state.drawers.masterProject,
    displayReminderDrawer: (state) => state.drawers.reminder,
    displayTaskDrawer: (state) => state.drawers.task,
    displayDocumentDrawer: (state) => state.drawers.document,
    displayApprovalDrawer: (state) => state.drawers.approval,
    displayChannelDrawer: (state) => state.drawers.channel,
  },

  state: {
    displaySearch: false,
    displayCreationModule: false,
    userConfirmationAction: false,
    // BEGIN drawers
    drawers: {
      masterProject: false,
      reminder: false,
      task: false,
      document: false,
      approval: false,
      channel: false,
    },
  },
  // END template Vuetify ----------------------
  strict: debug,
});

export default store;
