import axios from "axios";
import store from "../store/store";
import router from "@/router";

// Default config options
const defaultOptions = {
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PORT}`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
let instance = axios.create(defaultOptions); // skipcq: JS-0242

// Add to every request the 'aud' so it can be analyzed with the cookie

// Set the AUTH token for any request
instance.interceptors.request.use((config) => {
  return config;
});

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data?.redirect) {
      setTimeout(() => {
        response.data.redirect == "redirect_back"
          ? router.go(-1)
          : router.push(response.data.redirect);
      }, 500);
    } else if (response.data?.error == 9000) {
      // The action requested by the user needs a level over 9000
      // A confirmation is needed let's display that to the user
      store.commit("userConfirmationAction", response.data);
    }

    return response;
  },
  (error) => {
    if (
      error.response?.data?.exception &&
      error.response?.data?.exception.includes("JwtTokenNotFoundError")
    ) {
      return;
    }

    if (typeof error.response === "undefined") return error;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status === 401 ||
      error.response.status === "401 Unauthorized" ||
      error.response.data.message === "Unauthenticated."
    ) {
      router.push({
        path: "/signout",
        query: { revoked_token: true },
      });
    }

    let message =
      error.response.data.message ||
      error.response.data.error ||
      error.response.data.errors ||
      error ||
      "TODO: No error message found";

    // error is now in the format of `error_key: error_value`
    if (message.constructor.name === "Object") {
      const errors = message;
      message = "";
      const errorKeys = Object.keys(errors);
      errorKeys.forEach((errorKey) => {
        message += `${errorKey} ${errors[errorKey]}`;
      });
    }

    const tracing = error.response.data.tracing || null;

    // TODO: As a user i want better error from backend (more data such as error_type: warning or error) and adapt in frontend
    store.dispatch("alerts/error", { message, tracing });

    if (error.response.data?.redirect) {
      setTimeout(() => {
        error.response.data.redirect == "redirect_back"
          ? router.go(-1)
          : router.push(error.response.data.redirect);
      }, 2000);
    }

    return Promise.reject(message);
  },
);

export default instance;
