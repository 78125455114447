import axios from "@/axios";
import i18n from "@/i18n";
import router from "@/router";

const getDefaultState = () => {
  return {
    meReady: false,
    newEvent: false,
    me: {
      company: {
        name: "",
        address: "",
        companySetting: {
          design: {},
          privacy: {},
        },
        plan: "",
        trialEndAt: null,
      },
      myAuthorizations: {},
      userSettings: {
        appSettings: {
          language: null,
          deadlineWarningDay: 14,
        },
      },
    },
  };
};

const state = getDefaultState();

const actions = {
  addOne({ dispatch }, user) {
    return axios.post("/users/invitation", user).then((response) => {
      if (response.data?.error != 9000) {
        dispatch(
          "alerts/success",
          i18n.t("message.success.model_added", {
            model: i18n.t("record.user.model_name"),
          }),
          {
            root: true,
          },
        );
      }
      return response.data;
    });
  },
  // eslint-disable-next-line no-unused-vars
  acceptInvitation({}, user) {
    return axios.put(`/users/invitation`, { user: user });
  },
  // eslint-disable-next-line no-unused-vars
  // getMe({ dispatch, commit, getters, rootGetters }) {
  getMe({ commit }) {
    return axios
      .get("/v1/users/me")
      .then((response) => {
        const me = response.data;
        localStorage.setItem("acceptedLegalMention", me.acceptedLegalMention);
        commit("setMe", me);
        commit("setLocale", response.data.locale);
        return me;
      })
      .then((me) => {
        if (!me?.acceptedLegalMention) router.push("legalMention");
      });
  },
  updateMeAvatar({ commit, state }, userData) {
    const user = new FormData();
    user.append("user[avatar]", userData.avatar, userData.avatar.originalname);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.put(state.me.links.updateMe, user, config).then((response) => {
      const me = response.data;
      commit("setMe", me);
      commit("setLocale", me.locale);
    });
  },
  updateMe({ commit, state }, user) {
    return axios.put(state.me.links.updateMe, { user }).then((response) => {
      const me = response.data;
      localStorage.setItem("acceptedLegalMention", me.acceptedLegalMention);
      commit("setMe", me);
      commit("setLocale", me.locale);
    });
  },
  updateMeAppSettings({ commit, state }, { key, value }) {
    const appSettings = structuredClone(state.me.userSettings.appSettings);
    appSettings[key] = value;
    const user = { userSettingAttributes: { appSettings } };
    return axios.put(state.me.links.updateMe, { user }).then((response) => {
      const me = response.data;
      localStorage.setItem("acceptedLegalMention", me.acceptedLegalMention);
      commit("setMe", me);
      commit("setLocale", me.locale);
    });
  },
  updateEventsCount({ commit }, { eventsCount }) {
    commit("updateEventsCount", eventsCount);
  },
  newEvent({ commit }, value) {
    commit("newEvent", value);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setMe(state, me) {
    state.me = me;
    state.meReady = true;
  },
  setLocale(_state, locale) {
    i18n.locale = locale;
  },
  updateEventsCount(state, eventsCount) {
    state.me.eventsCount = eventsCount;
  },
  readReleaseNotes(state, value) {
    state.me.hasReadReleaseNotes = value;
  },
  newEvent(state, value) {
    state.newEvent = value;
  },
  removePhaseInvitation(state, phaseInvitation) {
    state.me.phaseInvitations = state.me.phaseInvitations.filter((pi) => {
      return pi.id !== phaseInvitation.id;
    });
  },
};

const getters = {
  me: (state) => state.me,
  meReady: (state) => state.meReady,
  darkMode: (state) => state.me?.userSettings?.appSettings?.darkMode || false,
  eventsCount: (state) => state.me?.eventsCount,
  newEvent: (state) => state.newEvent,
  objectViewMode: (state) =>
    state.me?.userSettings?.appSettings?.objectViewMode ?? "sidebar", // sidebar or modal
  objectSidebarWidth: (state) =>
    state.me?.userSettings?.appSettings?.objectSidebarWidth ?? "400px",
  menuSidebarWidth: (state) =>
    state.me?.userSettings?.appSettings?.menuSidebarWidth ?? "400px",
  acceptedLegalMention: (state) => state.me?.acceptedLegalMention,
  myUserID: (state) => state.me.id,
  myFullName: (state) => `${state.me.firstName} ${state.me.lastName}`,
  myLocale: (state) => state.me.locale,
  iCan: (state) => (auth) => state.me.myAuthorizations[auth],
  myCompany: (state) => state.me.company,
  isPremium: (state) => state.me.company.plan == "premium",
  isGoplaner: (state) => state.me.goplaner,
  remainingTrialDays: (state) => state.me.company.remainingTrialDays,
  appSettings(state) {
    return { ...state.me.userSettings.appSettings, ...state.me.settings };
  },
  navigationSettings: () =>
    state.me.userSettings?.appSettings?.navigationSettings ?? {},
  myPhaseInvitations: () => state.me?.phaseInvitations || [],
  hasPhaseInvitations: () => state.me?.phaseInvitations?.length > 0,
  hasReadReleaseNotes: () => state.me.hasReadReleaseNotes,
  shouldWorryOfDeadline: (state) => (deadline) => {
    if (!deadline) return false;
    const deadlineWarningDay =
      state.me.userSettings.appSettings.deadlineWarningDay;
    if (!deadlineWarningDay) return false;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dateStartToWorry = new Date(deadline);
    dateStartToWorry.setDate(dateStartToWorry.getDate() - deadlineWarningDay);

    return today >= dateStartToWorry;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
