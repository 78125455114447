import axios from "@/axios";

// =====================================================================
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setPhotos(state, data) {
    state.photos = data.list;
    state.currentPage = data.currentPage;
    state.isLastPage = data.currentPage === data.totalPages;
  },
  setSelectedPhotoIds(state, ids) {
    state.selectedPhotoIds = ids;
  },
  setFocusedPhotoId(state, id) {
    state.focusedPhotoId = id;
  },
  // update the photo attributes
  updatePhoto(state, photo) {
    state.photos = state.photos.map((oldPhoto) => {
      return oldPhoto.id === photo.id ? photo : oldPhoto;
    });
  },
  updatePhotos(state, photos) {
    state.photos = state.photos.map((oldPhoto) => {
      return photos.find((p) => p.id === oldPhoto.id) || oldPhoto;
    });
  },
  removePhotos(state, photos) {
    const photoIds = photos.map((p) => p.id);
    state.photos = state.photos.filter((photo) => {
      return !photoIds.includes(photo.id);
    });
  },
  append(state, photos) {
    const newPhotos = photos.filter(
      (photo) => !state.photos.some((p) => p.id === photo.id),
    );
    state.photos = state.photos.concat(newPhotos);
  },
  setNextPage(state, data) {
    state.currentPage = data.currentPage;
    state.isLastPage = data.currentPage === data.totalPages;
  },
  batchUpdateTag(state, newTag) {
    if (!newTag) return;
    state.photos = state.photos.map((e) => {
      e.tags = e.tags.map((tag) => {
        return tag.id === newTag.id ? newTag : tag;
      });
      return e;
    });
  },
  batchDeleteTag(state, deletedTag) {
    state.photos = state.photos.map((photo) => {
      photo.tags = photo.tags.filter((tag) => {
        return tag.id !== deletedTag.id;
      });
      return photo;
    });
  },
  setLatestClickCoordinates(state, coordinates) {
    state.latestClickCoordinates = coordinates;
  },
};

// =====================================================================
const actions = {
  setPhotos({ commit }, url) {
    return axios.get(url).then((response) => {
      commit("setPhotos", response.data);
    });
  },
  getFromIds({ commit }, { url, ids }) {
    if (!url) return console.error("no URL provided");
    if (!ids || ids.length === 0) return console.error("no IDs provided");
    return axios.get(url, { params: { ids } }).then((response) => {
      commit("append", response.data);
    });
  },
  getNext({ commit }, url) {
    return axios.get(url).then((response) => {
      commit("append", response.data.list);
      commit("setNextPage", response.data);
    });
  },
  setSelectedPhotoID({ commit }, photoId) {
    commit("setSelectedPhotoID", photoId);
  },
  selectNextPhoto({ commit, getters }) {
    const currentPosition = getters.selectedPhotoPosition;
    const photosCount = getters.photos.length;
    const newPosition = (currentPosition + 1) % photosCount;
    commit("setSelectedPhotoID", getters.photos[newPosition].id);
  },
  selectPreviousPhoto({ commit, getters }) {
    const currentPosition = getters.selectedPhotoPosition;
    const photosCount = getters.photos.length;
    const newPosition = (currentPosition + photosCount - 1) % photosCount;
    commit("setSelectedPhotoID", getters.photos[newPosition].id);
  },

  updatePhoto({ commit }, { photo, payload }) {
    const url = photo?.links?.update;
    if (!url) return console.error("no delete marker URL found");
    return axios.put(url, { marker: payload }).then((response) => {
      commit("updatePhoto", response.data);
      return response.data;
    });
  },
  updatePhotos({ commit }, { ids, payload }) {
    const url = "/v2/markers/batch_update";
    return axios.put(url, { ids, marker: payload }).then((response) => {
      commit("updatePhotos", response.data);
      return response.data;
    });
  },

  deletePhoto({ commit }, photo) {
    const url = photo.links.destroy ?? null;
    if (!url) return console.error("no delete marker URL found");
    return axios.delete(url).then((response) => {
      commit("removePhotos", [photo.id]);
      return response.data;
    });
  },
  deletePhotos({ commit }, photos) {
    const url = "/v2/markers/batch_destroy";
    const query = `ids=${photos.map((p) => p.id)}`;
    return axios.delete(`${url}?${query}`).then((response) => {
      commit("removePhotos", photos);
      return response.data;
    });
  },
};

// =====================================================================
const getters = {
  photos: (state) => state.photos,
  selectedPhotoIds: (state) => state.selectedPhotoIds,
  focusedPhotoId: (state) => state.focusedPhotoId,
  selectedPhotos: (state) =>
    state.photos.filter((photo) => state.selectedPhotoIds.includes(photo.id)),
  focusedPhoto: (state) =>
    state.photos.find((photo) => state.focusedPhotoId === photo.id),
  geoLocatedPhotos: (state) => state.photos.filter((photo) => photo.geolocated),
  selectedPhotoPosition: (state) => {
    const pos = state.photos.findIndex(
      (photo) => photo.id === state.selectedPhotoID,
    );
    return pos >= 0 ? pos : null;
  },
  selectedPhotoID: (state) =>
    state.photos.find((photo) => photo.id === state.selectedPhotoID)?.id,
  selectedPhoto: (state) =>
    state.photos.find((photo) => photo.id === state.selectedPhotoID),
  isLastPage: (state) => state.isLastPage,
  currentPage: (state) => state.currentPage,
  latestClickCoordinates: (state) => state.latestClickCoordinates,
};

// =====================================================================
const getDefaultState = () => {
  return {
    photos: [],
    selectedPhotoIds: [],
    focusedPhotoId: null,
    currentPage: 1,
    isLastPage: true,
    latestClickCoordinates: {
      lat: null,
      lng: null,
    },
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
