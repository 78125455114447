import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import LoggedInLayout from "../views/Layout/LoggedInLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
    component: LoggedInLayout,
    children: [
      {
        name: "Developers",
        path: "developers",
        component: () => import("@/views/devtools/developers/Developers"),
      },
      {
        name: "PersonalSettings",
        path: "myAccount",
        component: () => import("@/views/my-account/PersonalSettings"),
        meta: {
          hideHeader: true,
        },
      },
      {
        name: "MyCompanySettings",
        path: "companySettings",
        component: () => import("@/views/company-settings/Settings"),
        meta: {
          hideHeader: true,
        },
      },
      {
        name: "Dashboard",
        path: "",
        component: () => import("@/views/dashboards/Dashboard"),
        meta: {
          hideHeader: true,
        },
      },
      {
        name: "Notifications",
        path: "notifications",
        component: () => import("@/views/notifications/Notifications"),
        meta: {
          titleType: "translation",
          tkName: "router.notifications",
        },
      },
      {
        name: "ShowPhase",
        path: "phases/:phaseId",
        component: () => import("@/views/projects/Show"),
        meta: {
          hideHeader: true,
          hideFooter: true,
        },
      },
      {
        name: "ShowPhaseInvitation",
        path: "phase_invitations/:phaseInvitationUuid",
        component: () => import("@/views/phase-invitations/Show"),
        meta: {
          hideHeader: true,
        },
      },
    ],
  },
  {
    path: "/admin",
    component: LoggedInLayout,
    children: [
      {
        name: "Goplanalytics",
        path: "goplanalytics",
        component: () => import("@/views/admin/goplanalytics/Goplanalytics"),
      },
      {
        name: "Companies",
        path: "companies",
        component: () => import("@/views/admin/companies/Companies"),
      },
    ],
  },
  {
    path: "/",
    component: AuthBasicLayout,
    children: [
      {
        name: "SignOut",
        path: "signout",
        component: () => import("@/views/authentication/SignOut"),
      },
      {
        name: "PublicLink",
        path: "publicLinks/:id",
        component: () => import("@/views/public-links/PublicLink"),
        meta: {
          titleType: "translation",
          tkName: "router.public_link",
        },
      },
      {
        name: "PdfStatus",
        path: "pdf/:id",
        component: () => import("@/views/public-pdf/PdfStatus"),
        meta: {
          titleType: "translation",
          tkName: "router.pdf_status",
        },
      },
      {
        name: "LegalMention",
        path: "legalMention",
        component: () => import("@/views/goplan-components/LegalMention"),
        meta: {
          titleType: "translation",
          tkName: "router.legal_mention",
        },
      },
      {
        name: "CompanyRegistration",
        path: "registration",
        component: () => import("@/views/registration/CompanyRegistration"),
      },
    ],
  },
  {
    path: "/authentication",
    component: AuthBasicLayout,
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/authentication/LogIn"),
      },
      {
        name: "ConfirmEmail",
        path: "confirm-email",
        component: () => import("@/views/authentication/ConfirmEmail"),
      },
      {
        name: "ResetPassword",
        path: "reset-password",
        component: () => import("@/views/authentication/ResetPassword"),
      },
      {
        name: "EditPassword",
        path: "edit-password",
        component: () => import("@/views/authentication/EditPassword"),
      },
      {
        name: "RequestUnlock",
        path: "request-unlock",
        component: () => import("@/views/authentication/RequestUnlock"),
      },
      {
        name: "Unlock",
        path: "unlock",
        component: () => import("@/views/authentication/Unlock"),
      },
      {
        name: "Error",
        path: "error",
        component: () => import("@/views/authentication/Error"),
      },
    ],
  },
  {
    path: "/authentication",
    component: AuthCoverLayout,
    children: [
      {
        name: "Register",
        path: "register",
        component: () => import("@/views/authentication/SignUp"),
        meta: {
          hideFooter: true,
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/authentication/Error"),
  },
];

// Override the push function to catch the NavigationDuplicated Error Only
// https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete
        resolve(this.currentRoute);
      },
      (error) => {
        // on abort

        // only ignore NavigationDuplicated error
        if (error.name === "NavigationDuplicated") {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      },
    );
  });
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return new Promise((resolve, reject) => {
    originalReplace.call(
      this,
      location,
      () => {
        // on complete
        resolve(this.currentRoute);
      },
      (error) => {
        // on abort

        // only ignore NavigationDuplicated error
        if (error.name === "NavigationDuplicated") {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      },
    );
  });
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, _from, savedPosition) => {
    // let scrollTo = 0;

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }; // Go to the top of the page if no hash
    }
  },
  routes,
});

router.beforeResolve((to, _from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  const hadPopup = from.query.document || from.query.approval;
  const willHavePopup = to.query.document || to.query.approval;
  const isSamePage = from.name === to.name;
  const samePageAsPrevRoute = router.prevRoute?.name === from.name;
  const tabChanged = from.query.tab != to.query.tab;
  // save the the route we come from for future navigation
  if (
    (!isSamePage && !samePageAsPrevRoute) ||
    (tabChanged && !willHavePopup) ||
    (!hadPopup && willHavePopup)
  ) {
    router.prevRoute = from;
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/signout",
    "/authentication/login",
    "/authentication/register",
    "/authentication/reset-password",
    "/authentication/confirm-email",
    "/authentication/edit-password",
    "/authentication/request-unlock",
    "/authentication/unlock",
    "/registration",
    "/publicLinks",
    "/legalMention",
    "/pdf",
  ];
  const authRequired = !publicPages.some((publicPath) =>
    to.path.startsWith(publicPath),
  );
  const registerPage = ["/authentication/register", "/registration"];
  const onRegistration = registerPage.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("loggedIn")) ?? false;
  const acceptedLegalMention =
    JSON.parse(localStorage.getItem("acceptedLegalMention")) ?? false;

  // save where the user want to go in case of redirect
  const redirect = from.query?.redirect;

  // LegalMention is mandatory when logged in
  if (!acceptedLegalMention && loggedIn && to.name != "LegalMention") {
    next({ path: "/legalMention" });
    return;
  }

  // Don't go to signout in case of wrong login
  if (from.name === "Login" && to.name === "SignOut") {
    next({ name: "Login" });
  }

  // Want a page that requires to be logged in? -> then login please
  if (authRequired && !loggedIn) {
    return next({
      path: "/authentication/login",
      query: to.name !== "Logout" ? { redirect: to.fullPath } : {},
    });
  }

  // Don't go to a registration page if you are logged in
  if (onRegistration && loggedIn) {
    return next({ path: "/" });
  }

  // wanted to go somewhere and you got stopped before? now we serve it
  if (
    redirect &&
    acceptedLegalMention &&
    loggedIn &&
    to.fullPath !== redirect
  ) {
    return next({ path: redirect, query: null });
  }

  next();
});

export default router;
