const alert = {
  namespaced: true,
  state: {
    visible: false,
    icon: null,
    type: null,
    message: null,
    tracing: null,
    timeout: -1,
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, { message, tracing }) {
      commit("error", { message, tracing });
    },
    warning({ commit }, message) {
      commit("warning", message);
    },
    info({ commit }, message) {
      commit("info", message);
    },
    clear({ commit }) {
      commit("clear");
    },
  },
  mutations: {
    success(state, message) {
      state.icon = "mdi-check-bold";
      state.type = "success";
      state.message = message;
      state.timeout = 2000;
      state.visible = true;
    },
    error(state, { message, tracing }) {
      state.icon = "mdi-close-thick";
      state.type = "error";
      state.message = message;
      state.tracing = tracing;
      state.timeout = -1;
      state.visible = true;
    },
    warning(state, message) {
      state.icon = "mdi-alert-circle";
      state.type = "warning";
      state.message = message;
      state.timeout = 3000;
      state.visible = true;
    },
    info(state, message) {
      state.icon = "ni ni-bell-55";
      state.type = "info";
      state.message = message;
      state.timeout = 3000;
      state.visible = true;
    },
    clear(state) {
      state.visible = false;
      state.icon = null;
      state.type = null;
      state.message = null;
      state.tracing = null;
      state.timeout = -1;
    },
  },
};

export default alert;
