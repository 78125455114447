import axios from "@/axios";

const mutations = {
  set(state, { type, collection }) {
    collection = collection.map((resource) => {
      const shouldWorry = this.getters["users/shouldWorryOfDeadline"](
        resource.sortableDeadline || resource.deadline,
      );
      return { ...resource, shouldWorry };
    });
    state[type] = collection;
  },
  push(state, { type, resource }) {
    state[type] = [...state[type], resource];
  },
  setRemainingSeconds(state, seconds = 60) {
    state.remainingSeconds = seconds;
  },
};

const actions = {
  getAll({ commit }) {
    const url = "/v2/dashboards/navigation";
    commit("setRemainingSeconds", null);

    return axios.get(url).then((response) => {
      for (const [type, collection] of Object.entries(response.data)) {
        commit("set", { type, collection });
        commit("setRemainingSeconds");
      }
    });
  },
};

const getters = {
  remainingSeconds: (state) => state.remainingSeconds,
  reminders: (state) => state.reminders,
  tasks: (state) => state.tasks,
  documents: (state) => state.documents,
  approvals: (state) => state.approvals,
  hasLate: (state) => (type) => {
    if (!state[type]) return false;
    return state[type].some((resource) => resource.late);
  },
};

// =====================================================================
// we don't consider Project and Channel objects here
// because they are already handled by the masterProjects and Chats module
const getDefaultState = () => {
  return {
    remainingSeconds: 60,
    reminders: null,
    tasks: null,
    documents: null,
    approvals: null,
  };
};
const state = getDefaultState();

// =====================================================================
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
