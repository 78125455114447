<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
        :style="`background-image: url(${randomBackgroundImage}); background-position: 50%; background-size: cover;`"
      >
        <span
          class="mask bg-gradient-default border-radius-xl opacity-6 pt-5"
        ></span>

        <v-img
          class="mx-auto"
          contain
          max-width="60vw"
          max-height="100"
          :src="localPhoto"
        />
      </div>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
import randomCurvedImageMixin from "@/mixins/randomCurvedImageMixin";

export default {
  name: "page-layout",

  mixins: [randomCurvedImageMixin],

  components: {
    FadeTransition,
    ContentFooter,
  },
  data: () => ({
    localPhoto: "/goplan-white-no-bg.png",
  }),
};
</script>
