<template>
  <v-card class="card-shadow border-radius-xl" flat>
    <div class="pt-10">
      <div v-if="story" class="d-flex flex-column">
        <h3
          :class="$vuetify.breakpoint.lg ? 'px-16' : 'px-4'"
          class="
            text-center text-h3
            font-weight-bolder
            text-primary text-gradient
          "
        >
          {{ $t("view.premium.try") }}
        </h3>
        <Transition name="slide-fade" mode="out-in">
          <h1
            v-if="!showPricing"
            :class="$vuetify.breakpoint.lg ? 'text-h1 px-16' : 'text-h3 px-4'"
            class="text-center font-weight-bolder text-primary text-gradient"
            :style="!showPricing ? 'margin-bottom: 120px !important' : null"
            style="transition: 1s ease"
          >
            {{ story.title }}
          </h1>
        </Transition>
        <div
          :class="$vuetify.breakpoint.lg ? 'px-16' : 'px-4'"
          class="
            position-relative
            bg-gradient-primary
            h-100
            d-flex
            flex-column
            justify-center
          "
          style="transition: 1s ease; border-radius: 2rem 2rem 1rem 1rem"
        >
          <Transition name="slide-fade">
            <div v-if="!showPricing">
              <div class="position-relative" style="margin-top: -100px">
                <v-img
                  v-bind:src="getIllustration()"
                  alt="rocket"
                  max-width="500"
                  max-height="400"
                  contain
                  class="w-100 position-relative z-index-2 mx-auto"
                >
                </v-img>
              </div>
              <div class="mt-8">
                <Transition name="slide-fade">
                  <h4
                    class="
                      text-h4 text-center text-white
                      font-weight-bolder
                      mb-2
                    "
                  >
                    {{ story.text }}
                  </h4>
                </Transition>
              </div>
              <v-btn
                block
                color="white"
                x-large
                outlined
                class="heartbeat my-8 button-wrap-text"
                @click="showPricing = !showPricing"
              >
                {{ story.button }}
              </v-btn>
            </div>
          </Transition>
          <div>
            <Transition name="fade" mode="out-in">
              <div v-if="showPricing" class="mb-16">
                <h1
                  :class="$vuetify.breakpoint.lg ? 'text-h1' : 'text-h3'"
                  class="text-center font-weight-bolder text-white mt-16 mb-8"
                >
                  {{ $t("view.premium.offer") }}
                </h1>

                <!-- ================================================ -->
                <!-- BEGIN description premium features               -->
                <!-- ================================================ -->
                <v-row no-gutters justify="space-around">
                  <v-col style="max-width: 900px">
                    <div class="px-2 pt-6 pb-4 text-center">
                      <v-row justify="center">
                        <v-expansion-panels popout>
                          <v-expansion-panel
                            v-for="(item, i) in plan.features"
                            :key="i"
                            style="
                              background-color: rgb(1, 1, 1, 0.2);
                              border: rgb(255, 255, 255, 0.4) solid 1px;
                            "
                          >
                            <v-expansion-panel-header
                              color="transparent"
                              class="text-white"
                              disable-icon-rotate
                              ripple
                              >{{ featureTk("title", item) }}
                              <template v-slot:actions>
                                <v-icon size="24" color="white">
                                  mdi-information
                                </v-icon>
                              </template></v-expansion-panel-header
                            >
                            <v-expansion-panel-content
                              :class="$vuetify.breakpoint.lg ? 'px-16' : 'px-4'"
                              class="text-white white-space-pre-line"
                            >
                              {{ featureTk("description", item) }}
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                      <v-list
                        class="py-8 text-center text-white"
                        color="transparent"
                      >
                        <h1
                          class="
                            text-h1 text-center text-white
                            font-weight-bold
                            mt-1
                          "
                        >
                          <small>CHF </small>{{ plan.price }}
                        </h1>
                        <small class="text-center text-md">
                          {{ $t("view.premium.per_mont_per_seat") }}
                        </small>
                      </v-list>

                      <v-btn
                        elevation="0"
                        :ripple="false"
                        height="60"
                        class="
                          font-weight-bolder
                          py-3
                          text-xs
                          px-6
                          ls-0
                          w-100
                          border-radius-md
                          mb-4
                          bg-gradient-white
                        "
                        @click="goToBillingTab"
                        rel="noopener noreferrer"
                      >
                        {{ $t("view.premium.start_now") }}
                        <v-icon size="12">fas fa-arrow-right ms-2</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <!-- ================================================ -->
                <!-- END description premium features                 -->
                <!-- ================================================ -->
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  mounted() {
    let story = {};
    const theme = this.stories[Math.floor(Math.random() * this.stories.length)];
    // const theme = "treasure";
    story.svg = `${theme}.svg`;
    story.title = this.$t(`view.premium.${theme}.title`);
    story.text = this.$t(`view.premium.${theme}.text`);
    story.subText = this.$t(`view.premium.${theme}.sub_text`);
    story.button = this.$t(`view.premium.${theme}.button`);
    this.story = story;
  },
  data: () => ({
    showPricing: false,
    story: null,
    stories: [
      "treasure",
      "exploring",
      "call_me",
      "night_call",
      "healthy_habit",
      "star_dream",
    ],
    plan: {
      price: "60",
      features: [
        "create_project",
        // "phase_manager",
        "invite_partner",
        "internal_approval",
        "document_history",
        "dashboard",
        "private_channel",
        "custom_role",
      ],
    },
  }),
  methods: {
    featureTk(type, feature) {
      const text = `view.premium.feature.${feature}.${type}`;
      return this.$t(text);
    },
    getIllustration: function () {
      var images = require.context(
        "../../assets/illustrations/",
        true,
        /\.svg/,
      );
      return images("./" + this.story.svg);
    },
    goToBillingTab: function () {
      this.$router.push("/companySettings?tab=billings");
    },
  },
};
</script>

<style lang="scss">
.button-wrap-text {
  .v-btn__content {
    white-space: normal;
    flex: auto;
  }
}
</style>
