const mutations = {
  play(state, filename) {
    state.sound = filename;
  },
};

const actions = {};

const getters = {
  sound: (state) => state.sound,
};

const state = () => ({
  sound: null,
});

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
